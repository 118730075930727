@import 'src/styles/mixins';
@import 'src/styles/mediaQueries';

.hamburger-lines {
  display: none;
  height: 20px;
  width: 24px;
  position: absolute;
  top: -18px;
  left: 10px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include break($small) {
    display: flex;
  }

  .line {
    display: none;
    height: 4px;
    width: 100%;
    border-radius: 10px;

    @include break($small) {
      display: block;
    }
  }
  .line-1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
    &.open {
      transform: rotate(45deg);
    }
  }

  .line-2 {
    transition: transform 0.2s ease-in-out;
    &.open {
      transform: scaleY(0);
    }
  }

  .line-3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
    &.open {
      transform: rotate(-45deg);
    }
  }
}
