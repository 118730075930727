.onboarding-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 2.5rem 0 2.5rem 5rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0.5rem;
    position: initial;
    width: auto;
    height: 100vh;
    box-sizing: border-box;
  }
  .main-logo {
    width: 114px;
    height: 38px;
    align-self: flex-start;
    @media (max-width: 768px) {
      left: 0;
      top: 1rem;
      height: 24px;
    }
  }
  .onboarding-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    box-sizing: border-box;
    max-width: 1920px;
    max-height: 1080px;
    width: 100%;
    column-gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      justify-content: space-between;
      margin-top: 0px;
      width: 100%;
    }

    .left-section {
      height: 100%;
      max-width: 550px;
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      @media (max-width: 768px) {
        height: auto;
        align-items: center;
      }

      &__header {
        display: flex;
        flex-direction: column;

        width: 100%;
        margin-top: auto;

        @media (max-width: 768px) {
          padding-top: 1rem;
        }
        h1 {
          margin: 0;
          word-wrap: break-word;
          overflow-wrap: break-word;
          @media (max-width: 768px) {
            font-size: 24px;
            text-align: center;
          }
        }
        h3 {
          margin-top: 1rem;
          margin-bottom: 2rem;
          max-width: 507px;
          word-wrap: break-word;
          overflow-wrap: break-word;
          @media (max-width: 768px) {
            font-size: 18px;
            text-align: center;
            margin-bottom: 1rem;
          }
        }
        h3.create-password {
          width: 680px !important;
        }
      }
    }

    .right-section {
      flex-basis: 50%;
      box-sizing: border-box;
      @media (max-width: 768px) {
        width: 100%;
        height: calc(45vh - 38px);
        display: flex;
        justify-content: flex-end;
        img {
          height: 100%;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

.onboarding-image-wrapper-center {
  align-items: center;
  padding-left: 0;
  .main-logo {
    padding-left: 2.5rem;
  }
  .onboarding-container {
    justify-content: center;
    .left-section {
      align-items: center;
      .left-section__header {
        align-items: center;
        h1 {
          text-align: center;
        }
        h3 {
          text-align: center;
        }
      }
    }
  }
}
