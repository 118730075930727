.objective-title {
    margin-left: 1em;
    margin-top: 1.5rem;
}

.objective-wrapper {
    padding: 1em;
    margin: 1em;
}

.objective-data {
    display: grid;
    grid-template-rows: 1fr 0.75fr; // Two rows of equal height
    grid-template-columns: 1fr 0.75fr; // Two columns of equal width
    gap: 1rem; // Add a gap between grid items (adjust as needed)
    margin: 10% 0;

    .headline4-font {
        font-size: 13px;
    }
}

.objective  {
    h4, p {
        margin: 0.25rem 0;
    }
}