.upload-files {
  .file-label {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .type-selector {
    display: flex;
    column-gap: 0.5rem;
    margin-top: 1rem;
    align-items: center;
  }
  .files-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    overflow-y: auto;
    max-height: 300px;
    padding-right: 1rem;
  }
}
