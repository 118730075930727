@import '../../../../../styles/colorVariables.scss';


.offset-card {
    border: solid;
    border-width: 1px;
    border-radius: 15px;
    background-color: $white;
    border-color: $gray-color2;
}

.offset-card-hover:hover {
    border-color: $secondary-color;
}

.offset-card-second {
    background: linear-gradient(to right, rgba($primary-button-bg-color, 0.5), rgba($primary-button2-bg-color, 0.5));
}

.center-second-card {
    display: flex;
}