@import 'src/styles/colorVariables';

.infinite-scroll-wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 90%;
}

.infinite-scroll-container {
  border-radius: 8px;
  margin-top: 0.5rem;
  padding: 1.5rem;
  overflow-y: hidden;

  table {
    padding-bottom: 2rem;
    // colgroup {
    //   width: calc((100% / 10));
    // }
    tbody {
      tr {
        td:first-child {
          padding-right: 26px !important;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white;
    margin-bottom: 1rem;
  }
  th {
    padding-bottom: 0.5rem;
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 1rem;

    .table-filters {
      display: flex;
      column-gap: 0.5rem;
      flex: 1 1 auto;
      .input {
        max-width: 120px;
        min-width: 120px !important;
      }
      .calendar-range {
        max-width: fit-content;
      }

      .multi-select {
        flex: 1 1 auto;
        max-width: inherit;
        .input__select {
          min-width: 120px;
        }
      }
    }
    .table-tags {
      display: flex;
      column-gap: 1rem;
      justify-content: space-between;
      .buttons {
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
        white-space: nowrap;
        button {
          width: fit-content !important;
        }
      }
    }
  }
}
