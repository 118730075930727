.offset-summary {
  .image-header {
    width: 100%;
  }
  .offset-summary-characteristic {
    display: flex;
    gap: 8px;
    h2 {
      margin-top: 0;
    }
  }
  .offset-total {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
  }
  .offset-prices {
    margin-bottom: 8px;
  }

  button {
    margin-top: 8px;
  }
}
