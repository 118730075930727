@import '../../../../../src/styles/colorVariables';

.excel-table {
  table {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-spacing: 0px;
    border-color: $gray-color;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }

  th {
    text-align: left;
    border-right-width: 1px;
    border-top-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-style: solid;
    border-color: $gray-color;
    background-color: $tag-bg-color;
  }

  table tbody tr td {
    border-right-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    border-color: $gray-color;
    padding: 0;
  }

  tbody {
    tr:first-child td {
      border-top-width: 0px;
    }
  }

  table tbody tr td:last-child {
    border-right: 0px;
  }

  th:last-child {
    border-right: 0px;
  }
}

.excel-table.disabled {
  filter: opacity(0.7);
}