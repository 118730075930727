@import 'src/styles/colorVariables';

.input {
  &__google-maps {
    border-radius: 5px;
    border: 1px solid;
    align-items: center;
    .maps-autocomplete {
      display: flex;
    }
    &__content {
      border: none;
      padding: 14px;
      width: 100%;
      appearance: none;
      align-self: center !important;
      height: 100% !important;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .divider-line {
      width: 1px;
      height: 50%;
      align-self: center;
      background-color: $gray-color;
    }
  }
}

.maps-autocomplete {
  box-sizing: border-box;
  width: 90%;
}
