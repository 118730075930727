@import '../../../../../../../styles/fontClasses.scss';
@import '../../../../../../../styles/colorVariables.scss';

.wrapper-table-component {
  max-width: 100%;
  height: calc(100% - 5rem);
  padding: 2rem;
  padding-top: 0;

  .rc-table,
  .rc-table-container,
  .rc-table-content,
  table {
    height: 100%;
    width: 100%;
  }

  thead {
    @extend .headline4-font;
    text-align: left;
  }

  th {
    padding-bottom: 0.66rem;
    @extend .body1-bold-font;
  }

  td {
    @extend .input-small-font;
  }

  table {
    border-collapse: collapse;
  }

  .border {
    border-bottom: 1px solid $gray-color2;
    padding-bottom: 1rem;

    td {
      padding-bottom: 0.5rem;
      border-collapse: separate;
    }
  }

  .border-top {
    td {
      padding-top: 0.5rem;
    }
  }

  input {
    padding: 0;
  }

  .placeholder-secondary-color::placeholder {
    color: $secondary-text-color;
  }

  .input-container {
    display: flex;
    justify-content: space-around;
  }

  .label-loading-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.wrapper-input-number-modeling {
  border-radius: 3px;
  border: solid;
  border-color: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem !important;
  height: 0.9rem !important;
  border-width: 1px;

  input {
    text-align: center;
    width: 1.5rem;
  }
}
