@import 'src/styles/mediaQueries';
@import 'src/styles/shadow';

@for $i from 1 through 16 {
  $chAmount: $i * 5;

  .z-#{$i} {
    z-index: $i;
  }

  .max-w-#{$chAmount}ch {
    max-width: $chAmount * 1ch;
  }

  .grid-cols-#{$i} {
    display: grid;
    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
  }

  .md\:grid-cols-#{$i} {
    @media (max-width: $medium) {
      display: grid !important;
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr)) !important;
    }
  }

  .col-span-#{$i} {
    grid-column: span #{$i} / span #{$i};
  }

  .md-col-span-#{$i} {
    @media (max-width: $medium) {
      grid-column: span #{$i} / span #{$i};
    }
  }

  .col-start-#{$i} {
    grid-column-start: #{$i};
  }

  .t-#{$i} {
    top: $i * 0.25rem;
  }

  .r-#{$i} {
    right: $i * 0.25rem;
  }

  .top-#{$i} {
    top: $i * 0.25rem;
  }

  .right-#{$i} {
    right: $i * 0.25rem;
  }

  .bottom-#{$i} {
    bottom: $i * 0.25rem;
  }

  .left-#{$i} {
    left: $i * 0.25rem;
  }

  .stroke-width-#{$i}px {
    stroke-width: $i * 1px;
  }
}

@for $i from 1 through 100 {
  .w-#{$i} {
    width: $i * 0.25rem;
  }

  .max-h-#{$i} {
    max-height: $i * 0.25rem;
  }

  .min-w-#{$i} {
    min-width: $i * 0.25rem;
  }

  .h-#{$i} {
    height: $i * 0.25rem;
  }

  .min-h-#{$i} {
    min-height: $i * 0.25rem;
  }
}

@for $i from 1 through 100 {
  .max-chars-#{$i} {
    max-width: $i * 1ch;
  }

  .grid-auto-cols-#{$i} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($i * 0.25rem, 1fr));
  }
}

@for $i from 1 through 20 {
  $size: $i * 5;

  .max-h-#{$size}svh {
    max-height: #{$size}svh;
  }

  .h-#{$size}svh {
    height: #{$size}svh;
  }

  .min-h-#{$size}svh {
    min-height: #{$size}svh;
  }

  .w-percentage-#{$size} {
    width: $size * 1%;
  }

  .max-w-#{$i}rem {
    max-width: $i * 1rem;
  }
}

@for $i from 0 through 20 {
  .gap-#{$i} {
    gap: #{$i * 0.25}rem;
  }

  .gap-x-#{$i} {
    column-gap: #{$i * 0.25}rem;
  }

  .gap-y-#{$i} {
    row-gap: #{$i * 0.25}rem;
  }

  .my-#{$i} {
    margin-top: $i * 0.25rem;
    margin-bottom: $i * 0.25rem;
  }

  .mx-#{$i} {
    margin-left: $i * 0.25rem;
    margin-right: $i * 0.25rem;
  }

  .mt-#{$i} {
    margin-top: $i * 0.25rem;
  }

  .mr-#{$i} {
    margin-right: $i * 0.25rem !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * 0.25rem !important;
  }

  .ml-#{$i} {
    margin-left: $i * 0.25rem !important;
  }

  .m-#{$i} {
    margin: $i * 0.25rem !important;
  }

  .p-#{$i} {
    padding: $i * 0.25rem !important;
  }

  .px-#{$i} {
    padding-left: $i * 0.25rem !important;
    padding-right: $i * 0.25rem !important;
  }

  .py-#{$i} {
    padding-top: $i * 0.25rem !important;
    padding-bottom: $i * 0.25rem !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * 0.25rem !important;
  }

  .pl-#{$i} {
    padding-left: $i * 0.25rem !important;
  }

  .pt-#{$i} {
    padding-top: $i * 0.25rem !important;
  }

  .pr-#{$i} {
    padding-right: $i * 0.25rem !important;
  }
}
.flex {
  display: flex;
}
.justify-start{
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.items-baseline {
  align-items: baseline;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-start {
  align-items: flex-start;
}

.grow,
.flex-grow {
  flex-grow: 1;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.w-full {
  width: 100%;
}

.ml-auto {
  margin-left: auto;
}

.pointer {
  cursor: pointer;
}

.grid {
  display: grid;
}

.h-full {
  height: 100%;
}

.place-center {
  place-items: center;
}

.overflow-y-auto {
  overflow-y: auto;
}

// BORDERS

@for $i from 0 through 10 {
  .border-#{$i} {
    border-width: #{$i}px;
  }

  .hover\:border-#{$i}:hover {
    border-width: #{$i}px;
  }

  .border-b-#{$i} {
    border-bottom-width: #{$i}px;
  }

  .border-t-#{$i} {
    border-top-width: #{$i}px;
  }

  .border-l-#{$i} {
    border-left-width: #{$i}px;
  }

  .border-r-#{$i} {
    border-right-width: #{$i}px;
  }
}

@for $i from 0 through 20 {
  .rounded-#{$i} {
    border-radius: #{$i}px;
  }

  .rounded-t-#{$i} {
    border-top-left-radius: #{$i}px;
    border-top-right-radius: #{$i}px;
  }

  .rounded-b-#{$i} {
    border-bottom-left-radius: #{$i}px;
    border-bottom-right-radius: #{$i}px;
  }

  .rounded-l-#{$i} {
    border-top-left-radius: #{$i}px;
    border-bottom-left-radius: #{$i}px;
  }

  .rounded-r-#{$i} {
    border-top-right-radius: #{$i}px;
    border-bottom-right-radius: #{$i}px;
  }

  .rounded-percentage-#{$i} {
    border-radius: $i * 1%;
  }
}

.border-solid {
  border-style: solid;
}

.border-r-solid {
  border-right-style: solid;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.underline {
  text-decoration: underline;
  text-decoration-color: inherit;
}

.list-style-none {
  list-style-type: none;
}

.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-w-full {
  max-width: 100%;
}

.break-all {
  word-break: break-all;
}

.keep-all {
  word-break: keep-all;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end;
}

.border-box {
  box-sizing: border-box;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.grid-auto-rows {
  grid-auto-rows: minmax(0, 1fr);
}

.w-fit-content {
  width: fit-content;
}

.overflow-hidden {
  overflow: hidden;
}

.grid-row-template-full {
  grid-template-rows: repeat(1, 100%);
}

.img-contain {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.self-end {
  align-self: flex-end;
}

.text-justify {
  text-align: justify;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.justify-self-end {
  justify-self: flex-end;
}

.ml-auto {
  margin-left: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.width-100 {
  width: 100%;
}

.width-75 {
  width: 50%;
}

.hover\:box-shadow-20:hover {
  box-shadow: $shadow-20;
}

.hover\:border-solid:hover {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.whitespace-normal-row > .rc-table-cell {
  white-space: normal !important;
}

.select-none {
  user-select: none;
}

.cursor-drag {
  cursor: grab;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.border-transparent {
  border-color: transparent;
}
.wrap {
  flex-wrap: wrap;
}

.ol {
  list-style-type: decimal;
}

.mt-auto {
  margin-top: auto;
}

.height-fit-content {
  height: fit-content;
}

.place-items-center {
  place-items: center;
}

.self-start {
  align-self: flex-start;
}
.shadow-purple-dark {
  box-shadow: $shadow-purple-dark;
}

.shrink-0 {
  flex-shrink: 0;
}