.code-management {
  &__layout {
    display: grid;
    grid-template-rows: 4.5rem 3rem 1fr;
    // gap: 1rem;
    height: calc(100% - 2rem);
  }

  &__list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__filters {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
