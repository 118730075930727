.text-wrapper-gap {
  display: flex;
  flex-direction: column;
  gap: 10%;
}

.text-wrapper-gap-card {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .button-wrapper-modal {
    display: flex;
    button {
      width: 200px !important;
      height: 36px;
      padding: 10px;
    }

    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}

.element-offset {
  display: flex;
  align-items: center;
  margin: 0;
}

.image-checked {
  margin-right: 0.66rem;
}

.list-wrapper-offset {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
