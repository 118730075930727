.premium-feature-modal__content{
    width: 700px;
    position: relative;
    .premium-feature-modal__download-button-left{
        width: 49.4%;
        height: 8%;
        position: absolute;
        left: 0;
        bottom: 0;
        cursor: pointer;
    }
    .premium-feature-modal__download-button-right{
        width: 49.4%;
        height: 8%;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }
}