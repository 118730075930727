.progress-wrapper {
    max-height: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 2.5rem 2.5rem 1fr;
}

.progress-legend-section {
    display: flex;
    gap: 0.33rem;
    margin-left: 1rem;
}

.progress-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border-radius: 3px;
}