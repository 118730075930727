.snippet-upload {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .snippet-upload-wrapper {
    display: flex;
  }
  .snippet-upload-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    height: fit-content;
    .button-wrapper {
      display: flex;
      margin-top: 1rem;
      column-gap: 2rem;
    }
    .input {
      margin-top: 1rem;
    }
    .categories-selected {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .category-selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
        font-size: 12px;
        img {
          cursor: pointer;
          width: 12px;
        }
      }
    }
  }
  .snippet-upload-html-files-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    width: 50%;
    overflow-y: auto;
    max-height: 88vh;
  }
}
.snippet-download-card {
}
.snippet-preview {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .no-comparison {
    .logoWrapper {
      justify-content: flex-start !important;
    }
  }

  .cardHeader {
    img {
      width: 64px !important;
      height: 64px !important;
    }
    .cardTitle {
      h1 {
        font-size: 2rem !important;
        color: #000 !important;
      }
      .contamination {
        p {
          font-size: 2rem !important;
          color: #000 !important;
        }
        span {
          font-size: 1.5rem !important;
          color: #000 !important;
        }
      }
    }
  }
  .cardFooter {
    .comparation {
      span {
        font-size: 1.3rem !important;
        color: #000 !important;
      }
    }
  }
  .percentageLegend {
    span {
      font-size: 1rem !important;
      color: #000 !important;
    }
  }
}

.snippets-wrapper {
  overflow-y: auto;
  max-height: 80vh;
}
