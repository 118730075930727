.video-tutorial-popup {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  column-gap: 1rem;
  overflow: hidden;
  cursor: move;
  user-select: none;
  z-index: 2;
  bottom: 0.5rem;
  right: 6.5rem;

  h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .video-icon {
    height: 60px;
    width: 60px;
    border-radius: 50%; 
}
}


