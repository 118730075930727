@import 'src/styles/utils';
@import 'src/styles/colorVariables';
@import 'src/styles/fontClasses';

.label {
  @extend .gap-x-2, .pointer, .items-center, .font-body-b2-r;
  z-index: 1;
  margin-left: 6px;
  margin-block: 6px;

  display: grid;
  grid-template-columns: 1rem auto;

  color: $on-light-text-color;

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0px;
    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    background-clip: padding-box;
    display: grid;
    place-content: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -2px;
      border-radius: inherit;
      background: $gradient-color;
    }

    &::after {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background: $gradient-color;
      z-index: 1;
    }

    &:checked {
      &::after {
        transform: scale(1);
      }
    }
  }

  &[aria-disabled='true'] {
    color: $gray-light-color;
    cursor: not-allowed;

    input[type='radio'] {
      cursor: not-allowed;
      &::before,
      &::after {
        background: $gray-light-color;
      }
    }
  }

  &[aria-disabled='false'] {
    input[type='radio'] {
      &:hover {
        box-shadow: inset 0 0 0 6px $input-shadow-hover-color, 0 0 0 6px $input-shadow-hover-color;
      }

      &:active {
        box-shadow: inset 0 0 0 6px $input-shadow-active-color, 0 0 0 6px $input-shadow-active-color;
      }
    }
  }
}
