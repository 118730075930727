.business-travels {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 4.5rem 0.3fr 1fr;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }
  &__body {
    display: flex;

    flex-wrap: wrap;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .business-travels-card {
      margin: 10px;
      padding: 13px 13px 18px 13px;
      border-width: 1px;
      border-style: dashed;
      border-radius: 5px;
      box-sizing: border-box;
      width: calc(100% - 20px);

      h1 {
        margin: 0 auto 6px auto;
        text-align: center;
      }

      p {
        margin: 0 auto 16px auto;
        text-align: center;
      }

      .buttons {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        button {
          width: auto !important;
        }
      }
    }
  }
  .business-travels-wrapper {
    border-radius: 5px;
    margin-top: 12px;
    padding: 33px 36px;
    .icon-text-wrapper {
      img {
        margin-right: 12px;
      }
    }
    table {
      colgroup {
        width: calc((100% / 7));
      }
      tbody {
        tr {
          td:first-child {
            padding-right: 26px !important;
          }
        }
      }
    }
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .table-filters {
        display: flex;
        .input {
          margin-right: 9px;
          min-width: 120px;
          flex: 0 0 0;
        }
        .calendar-range {
          width: 210px;
          min-width: 210px;
        }
        .multi-select {
          .input__select {
            min-width: 120px;
          }
        }
      }
      .total-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}

.edit-business-travel {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 48px;
  }
  .select-inputs-wrapper {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 28px;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    .multiselect-label {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      margin-right: 4px;
      margin-left: 4px;
    }
    .multiselect-card-section {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      margin-right: 4px;
      margin-left: 4px;
      margin-bottom: 16px;
    }
    .input {
      flex-grow: 1;
      flex-shrink: 0.5;
      flex-basis: calc(50% - 8px);

      margin-right: 4px;
      margin-left: 4px;
      box-sizing: border-box;
      margin-bottom: 16px;
    }

    .origin-destination-wrapper {
      width: 98%;
    }

    .buttons {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      span {
        cursor: pointer;
      }
    }
  }
  .buttons {
    margin-top: 16px;
  }
}

.delete-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
