@import 'src/styles/mixins';
@import 'src/styles/mediaQueries';

.main-app {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .main-app-wrapper {
    display: flex;
    padding: 0px 20px 0px 0px;
    margin-left: auto;
    margin-right: auto;
    max-height: 1080px;
    height: 100%;
    align-items: stretch;
    box-sizing: border-box;
    overflow-y: auto;

    @include break($small) {
      padding: 20px;
    }

    .main-app-content {
      position: relative;
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 1920px;
      padding-top: 20px;
      padding-left: 15px;
      max-height: 1080px;
      box-sizing: border-box;

      @include break($small) {
        padding-left: 0px;
        margin-top: 1rem;
      }
    }
  }
}
