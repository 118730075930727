.admin-tasks {
  padding-bottom: 3rem;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
    box-sizing: border-box;
    .input {
      width: 50%;
    }
  }
  .rc-table-cell:nth-child(2) {
    width: 15%;
  }
  .rc-table-cell:nth-child(3) {
    width: 40%;
  }
  .rc-table-cell {
    a {
      word-break: break-all;
    }
  }
}
