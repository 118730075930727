@import 'src/styles/colorVariables';
.slide-toggle-wrapper2 {
  display: flex;
  align-items: center;
  .slide-toggle {
    width: fit-content;
    // height: 14px;
    padding: 0.2rem;
    box-sizing: border-box;
    margin-right: 4px;
    border-radius: 1rem;
    background-color: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    .status-switch {
      padding: 0.3rem 0.5rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      .tooltip {
        width: fit-content;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .tooltip-modal {
          min-width: 200px;
        }
      }
      span {
        display: flex;
      }
    }
  }
}
