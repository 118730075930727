.basic-input {
  width: 100%;
  min-width: 0;
  padding: 0.5em;
}

.form-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 8px;
  .input,
  .input-element {
    width: 100%;
    flex-basis: calc(50% - 8px);
    flex-grow: 1;
    @media (max-width: 768px) {
      flex-basis: 100%;
    }
  }
}

input {
  background: content-box;
  border: none;
}

.input {
  width: auto;
  min-width: 0;
  display: flex;
  flex-direction: column;
  &--basic {
    margin-bottom: 0px !important;
  }
  .label-wrapper {
    display: flex;
    min-height: 1rem;
    label {
      // margin-right: 8px;
    }
    img {
      width: 12px;
      height: 12px;
    }
    .tooltip {
      align-self: flex-end;
    }
  }
  label {
    display: flex;
    align-self: flex-start;
    align-items: flex-end;
    // margin-bottom: 8px;
    height: 1rem;
    gap: 0.5rem;
  }
  .small {
    .image-wrapper {
      padding: 10px !important;
      img {
        width: 12px;
        height: 12px;
      }
    }
    input,
    .input__select__content {
      padding: 0px 5px !important;
      height: calc(100% - 10px);
      box-sizing: content-box;
      width: 100%;
      color: $input-text-color;
    }

    .select-options {
      top: calc(100%);
      // width: 100%;
    }
    .input--basic {
      .select-options {
        width: calc(100% + 5px) !important;
      }
    }
  }
  input::placeholder {
    color: $gray-color;
  }

  .placeholder {
    color: $gray-color;
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    border-right-width: 1px;
    border-right-style: solid;

    height: 100%;
    box-sizing: border-box;

    img {
      width: 18px;
      height: 18px;
    }
  }
  // input {
  //   background: $input-bg-color;
  // }
}

span.input-error-text,
span.change-password-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
