@import 'src/styles/colorVariables';
.improve {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  position: relative;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 0px;
    }
    h4 {
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
  &__mode {
    margin-top: 1rem;
  }

  &__body {
    height: calc(91.5% - 17px - 1.5rem);
    margin-top: 1rem;
    .co2-info {
      display: flex;
      align-items: stretch;
      column-gap: 1rem;
      width: 100%;
      .card {
        width: 33%;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .card-header {
          display: flex;
          justify-content: space-between;
          h5 {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
        .card-dashboard {
          display: flex;
          justify-content: space-around;
          margin-top: 4%;
          border-width: 1px;
          border-style: solid;
          padding: 4%;
          border-radius: 5px;
          box-sizing: border-box;
          height: 100%;
          .dashboard-first-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            .co2-wrapper {
              width: 8rem;
              height: 8rem;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;

              @media (max-width: 1300px) {
                margin-left: 0px;
              }

              .co2-progress-bar {
                position: relative;
                // height: 80px;
                height: 80%;
                width: 80%;
                box-sizing: border-box;

                .co2-information {
                  position: absolute;
                  top: 30%;
                  left: 26%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  width: 50%;
                  .co2-value {
                    text-align: center;
                  }
                  .co2-units {
                    text-align: center;
                  }
                }
              }
            }

            .button-wrapper {
              white-space: nowrap;
              margin-top: auto;
            }
          }
          .scopes {
            margin-top: 2%;
            margin-left: 5%;
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            .tag-wrapper {
              width: 100%;
            }
          }
        }

        .card-offset {
          display: flex;
          margin-top: 4%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          row-gap: 12px;
          padding: 4%;
          border-radius: 5px;
          box-sizing: border-box;
          h5,
          p {
            margin: 0;
          }
          button {
            width: fit-content !important;
          }
        }

        .card-recommendation {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          margin-top: 4%;
          row-gap: 14px;
          .card {
            width: 100%;
            height: 33.3%;
            margin-left: 0;
            padding: 4%;
            display: flex;
            flex-direction: row !important;
            align-items: center;
            justify-content: center;
            position: relative;
            .recommended-tag {
              width: 24px;
              height: 24px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: -12px;
              right: 15px;
              padding: 5px;
              box-sizing: border-box;
            }
            .step-info {
              display: flex;
              flex-direction: column;
              margin-left: 16px;
              span:first-child {
                margin-bottom: 4px;
              }
              .tags {
                display: flex;
                margin-top: 8px;
                .pending-tag {
                  padding: 2px 5px;
                  border-radius: 3px;
                  margin-right: 6px;
                }
                .percentage-tag {
                  padding: 2px 5px;
                  border-radius: 3px;
                }
              }
            }
            button {
              width: 28% !important;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
