.limit-completed-vehicles {
  h1 {
    margin-top: 0;
    margin-bottom: 32px;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    width: 90%;
  }
  button {
    width: 50% !important;
  }
}
