@import 'src/styles/colorVariables';

.subsection {
  display: flex;
  padding-left: 5px;
  border-radius: 0px 6px 6px 0px;

  span {
    width: 100%;
    padding-left: 16px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    cursor: pointer;
  }
}
.subsection:hover,
.active {
  background-color: $section-active-bg-color;
  color: $on-section-active-text-color;
  width: 100%;
}
