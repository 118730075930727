.review-image {
  border-radius: 16px;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100vh - 38px - 6rem);
  &__profile {
    width: 75%;
    padding-left: 3.5rem;
    p {
      margin-top: 0px;
    }
    &__image-wrapper {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      img {
        width: 48px;
        height: 48px;
      }
      &__text {
        display: flex;
        flex-direction: column;
        row-gap: 0.1rem;
        p {
          margin: 0;
        }
      }
    }
  }
  &__main-image {
    // margin-top: 2rem;
    align-self: flex-end;
    width: 100%;
    border-bottom-right-radius: 16px;
  }
}
