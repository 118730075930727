@import 'src/styles/colorVariables';

.tag-wrapper {
  background-color: $gray-dashboard;
  border-radius: 5px;
  padding: 5.75px;
  display: flex;
  align-items: center;
  height: fit-content;
  @media (max-width: 1400px) {
    span {
      font-size: 10px;
      line-height: 120%;
    }
    img {
      max-width: 12px;
      max-height: 12px;
    }
  }
  img {
    max-width: 14px;
    max-height: 14px;
  }

  .tag-elem {
    display: flex;
    align-items: center;
  }

  * {
    margin-right: 4px;
    margin-left: 4px;
    white-space: nowrap;
  }
  *:first-child {
    margin-left: 0 !important;
  }
  *:last-child {
    margin-right: 0 !important;
  }
}

.tag-wrapper--row {
  flex-direction: row;
}
.tag-wrapper--column {
  flex-direction: column;
  row-gap: 4px;
}

.wrapper-info-tag {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 6px;
  img {
    margin-right: 20px;
  }
}
