@import 'src/styles/colorVariables';

.label-div {
  display: flex;
  align-items: center;
  text-align: center;
  width: fit-content;
}

.label-div.secondary {
  background-color: $secondary-color;
  color: $secondary-light-color;
}

.label-div.error {
  background-color: $error-background;
  color: $strong-error-color;
}

.label-div.success {
  background-color: $primary-light-color-10;
  color: $primary-light-color;
}

.label-div.warning {
  background-color: $warning-bg-color;
  color: $warning-color;
}

.label-div.medium {
  padding: 4px 8px;
  border-radius: 16px;
}
.label-div.small {
  padding: 2px 4px;
  border-radius: 12px;
}
