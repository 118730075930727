.how-to-export-information-card {
    padding: 1rem 1.5rem;
    border-radius: 4px;

    width: 100%;
    min-height: 70px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    &__content {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      width: 100%;
      .client-image {
        width: 50px;
      }
      .chevron {
        margin-left: auto;
      }
      .rotate90 {
        transform: rotate(90deg);
      }
    }

    .iframe-container {
      height: 300px;
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }