.select-invoice-type {
  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  .types {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
}
