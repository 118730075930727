@import 'src/styles/colorVariables';

.select-options-list {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0.5rem;
  max-height: 120px;
  .select-options__item {
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
  }
  .select-options__item:hover,
  .active {
    background: linear-gradient(
      to right,
      $select-options-active-item-bg-color,
      $select-options-active-item2-bg-color
    );
    color: $select-options-active-item-text-color;
  }
}
