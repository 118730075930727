@import 'src/styles/colorVariables';
@import 'src/styles/fontClasses';

.recommendations {
  width: 100%;
  height: 100%;
  position: relative;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 0px;
    }
    h4 {
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
  &__mode {
    width: fit-content;
    margin-top: 1rem;
  }
  &__body {
    display: flex;
    flex-direction: column;
    height: calc(91.5% - 17px);
    column-gap: 1rem;
    .card {
      padding: 20px;
      box-sizing: border-box;
      .card-header {
        display: flex;
        justify-content: space-between;
        h5 {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
      .card-body {
        display: flex;
        margin-top: 9px;
        .card-column {
          width: 50%;
        }
      }
    }
  }

  &__slider {
    display: flex;
    align-items: center;
    margin-top: 1.25%;
    height: 10%;
    column-gap: 1rem;

    .card:first-child {
      margin-left: 0px;
    }

    .card:last-child {
      margin-right: 0px;
    }

    .card {
      // width: 20%;
      // margin-right: 18px;
      width: auto;
      flex: 1 1 auto;
      padding: 8px 19px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      .recommended-tag,
      .premium-tag {
        width: 24px;
        height: 24px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -12px;
        right: 15px;
        padding: 5px;
        box-sizing: border-box;
      }
      .premium-tag {
        border: 1px solid $secondary-color;
      }
      .step-info {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        span:first-child {
          margin-bottom: 4px;
        }
        span {
          white-space: nowrap;
        }
        .tags {
          display: flex;
          margin-top: 8px;
          .pending-tag,
          .completed-tag {
            padding: 2px 5px;
            border-radius: 3px;
            margin-right: 6px;
          }
          .percentage-tag {
            padding: 2px 5px;
            border-radius: 3px;
          }
        }
      }
    }
    .card--selected,
    .card:hover {
      border: 1px solid $primary-color !important;
    }
    .card:last-child {
      margin-right: 0;
    }
  }
  &__detail {
    margin-top: 1.5%;
    height: 70%;
    .card {
      box-sizing: border-box;
      padding: 2% 75px;
      margin-left: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      column-gap: 1rem;
      .card-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        flex-basis: 33%;
        height: 100%;
        .card-inside {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 1rem;

          .card-inside-column {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-basis: 50%;
            height: 100%;
          }
        }

        .buttons {
          display: flex;
          margin-top: 46px;
          width: 80%;
          align-self: flex-start;
          flex-direction: column;
          max-width: 285px;
          button {
            margin-right: 5px;
            width: 65% !important;
          }
          .cancel-button-wrapper {
            display: flex;
            margin-top: 10px;
            span:first-child {
              margin-right: 5px;
            }
            span:last-child {
              cursor: pointer;
            }
          }
        }

        .recommendation-img {
          width: 100%;
        }
        .characteristics-wrapper {
          .characteristic {
            display: flex;
            align-items: flex-start;
            margin-bottom: 5px;
            img {
              margin-right: 6px;
              margin-top: 1px;
            }
          }
        }
        a {
          margin-top: 5px;
          margin-left: 20px;
        }
      }
      .card-column:first-child {
        flex-grow: 2;
      }
      .card-column:last-child {
        margin-right: 0 !important;
      }
    }
  }

  &__title {
    @extend .headline1-font;
    margin-right: 75px;
    margin-top: 0px;
    margin-bottom: 29px;
  }

  &__title-small {
    @extend .recommendations__title;
    width: 400px;
  }
}
