@import 'src/styles/colorVariables';
.input-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 51px;
  border-radius: 5px;
  button {
    white-space: nowrap;
    display: inline-flex;
    width: auto !important;
  }
  label {
    margin-top: 12px;
  }
}

.input-file-invoice {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  &__img {
    width: 83px;
    height: 81px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      color: $white;
      position: absolute;
      padding-bottom: 1px;
      font-size: 13px;
      bottom: 0;
      right: 0;
    }
  }
}
