@import 'src/styles/colorVariables';

.stacked-bar {
  height: 8px;
  display: flex;
  max-width: 150px;
  min-width: 150px;
  background-color: $gray-color2;
  border-radius: 0px 8px 8px 0px;
  .bar {
    height: 100%;
    border-radius: 0px 8px 8px 0px;
    margin-right: -2px;
  }
}

.stacked-bar-vertical {
  width: 8px;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  background-color: $gray-color2;
  border-radius: 8px 8px 0px 0px;
  .bar {
    border-radius: 8px 8px 0px 0px;
  }
}
