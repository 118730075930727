.socialAndGovernance {
    position: relative;
    width: 100%;
    height: 100%;
    margin-right: 12px;
    display: grid;
    grid-template-rows: 4.5rem 0.3fr 1fr;
  
    &__header {
        width: 80%;
        h3 {
          margin-bottom: 8px;
        }
      }
    &__body {
      display: flex;
  
      flex-wrap: wrap;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      @media (max-width: 1000px) {
        flex-direction: column;
      }
  
      .socialAndGovernance-card {
        margin: 10px;
        padding: 13px 13px 18px 13px;
        border-width: 1px;
        border-style: dashed;
        border-radius: 5px;
        box-sizing: border-box;
        width: calc(100% - 20px);
  
        h1 {
          margin: 0 auto 6px auto;
          text-align: center;
        }
  
        p {
          margin: 0 auto 16px auto;
          text-align: center;
        }
  
        .buttons {
          display: flex;
          justify-content: center;
          button {
            width: auto !important;
            margin-right: 8px;
          }
          button:last-child {
            margin-right: 0;
          }
        }
      }
    }

  }
