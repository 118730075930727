@import 'src/styles/fontVariables';
@import 'src/styles/colorVariables';
.small {
  tbody,
  tfoot {
    tr {
      td {
        font-size: 11px;
      }
    }
  }
}
.spinner-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1em;
}
.medium {
  tbody {
    tr {
      td {
        font-size: 14px;
      }
    }
  }
}

.table-wrapper {
  width: 100%;

  .loading-skeleton-wrapper {
    .loading-skeleton {
      flex-basis: 100% !important;
      width: 100% !important;
      flex-shrink: 0 !important;
    }
  }

  table {
    width: 100%;
    border-spacing: 0px;
    thead {
      display: table-header-group;
      th {
        font-family: $font-regular;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.03em;
        line-height: 120%;
        font-size: 12px;
        text-align: left;
        vertical-align: top;
        padding-right: 8px;
      }
    }

    tbody,
    tfoot {
      tr {
        td {
          padding: 21px 8px 21px 0px;
          font-family: $font-regular;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.03em;
          line-height: 120%;
          text-align: left;
          margin-right: 26px;
          border-bottom: 1px solid rgba(164, 166, 179, 0.5);
          .icon-text-wrapper {
            display: flex;
            align-items: center;
            img {
              margin-right: 5px;
            }
          }
        }
      }
      tr:last-child td {
        border: none;
      }
      tr.disabled {
        td {
          color: $disabled-color !important;
        }
      }
    }
  }
}

@for $i from 0 through 19 {
  .table-columns-#{$i} {
    table {
      colgroup {
        width: calc((100% / #{$i}));
      }
    }
  }
}
