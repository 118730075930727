.organization-description-form {
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 0.3rem;
    .label-sticker-wrapper {
      margin-top: 0;
      margin-right: 8px;
    }
    .select-all {
      white-space: nowrap;
    }
  }
}
