@import '../../../../styles/fontClasses.scss';

.table-standard {
  th {
    text-align: left;
    @extend .body2-font;
    padding: 10px 16px;
  }

  td {
    text-align: left;
    @extend .body1-font;
    padding: 10px 16px;
  }
}
