.configure-company {
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 26px;
  }

  .checkbox-margin {
    margin-top: 8px;
  }
  .buttons {
    margin-top: 21px;
    display: flex;
    column-gap: 8px;
  }
}
