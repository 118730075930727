.error-page {
  &__logo {
    position: absolute;
    top: 2rem;
    left: 7.25rem;
    width: 114px;
    height: 38px;
  }

  &__main-image {
    width: 11.875rem;
    height: 11.875rem;
  }

  &__text {
    text-align: center;
  }

  &__button-wrapper {
    width: 50%;
  }
}
