@import 'src/styles';

.link-gray-dark {
  @extend .on-light-text-color, .pointer;
  position: relative;
  border: none;
  padding: 0;

  background-color: transparent;
  width: fit-content;

  display: flex;
  align-items: center;

  text-decoration: underline;
  text-decoration-color: $on-light-text-color;
}
