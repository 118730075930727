@import 'src/styles/fontVariables.scss';
.ghg-report {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(180deg, #000000 0%, rgba(25, 42, 62, 0.8) 100%);

  .weight-600 {
    font-weight: 600;
    font-family: $font-semibold;
  }

  .icon-wrapper {
    img {
      width: 10px;
    }
  }
  .buttons {
    display: flex;
    column-gap: 1rem;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: 16px;
    button {
      border: none !important;
    }
  }

  .report-footer-logo {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 32px;
    right: 38px;
    column-gap: 0.5rem;
    img {
      height: 18px;
    }
  }

  .list-description-wrapper {
    display: flex;
    column-gap: 1rem;
    .ghg-report-list {
      width: 100%;
    }
    .description-box {
      width: 60%;
    }
  }
}
