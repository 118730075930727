.shipments {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 4.5rem 0.3fr 1fr;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }
  &__body {
    display: flex;

    flex-wrap: wrap;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    .shipments-card {
      margin: 10px;
      padding: 13px 13px 18px 13px;
      border-width: 1px;
      border-style: dashed;
      border-radius: 5px;
      box-sizing: border-box;
      width: calc(100% - 20px);

      h1 {
        margin: 0 auto 6px auto;
        text-align: center;
      }

      p {
        margin: 0 auto 16px auto;
        text-align: center;
      }

      .buttons {
        display: flex;
        justify-content: center;

        gap: 0.5rem;

        button {
          width: auto !important;
        }
      }
    }
  }
  .shipment-wrapper {
    border-radius: 5px;
    margin-top: 12px;
    padding: 33px 36px;
    .icon-text-wrapper {
      img {
        margin-right: 12px;
      }
    }
    table {
      colgroup {
        width: calc((100% / 7));
      }
      tbody {
        tr {
          td:first-child {
            padding-right: 26px !important;
            white-space: nowrap;
          }
        }
      }
    }
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .table-filters {
        display: flex;
        .input {
          margin-right: 9px;
          min-width: 120px;
          flex: 0 0 0;
        }
        .calendar-range {
          width: 210px;
          min-width: 210px;
        }
        .multi-select {
          .input__select {
            min-width: 120px;
          }
        }
      }
      .total-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}

.upload-shipments-csv {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 48px;
  }
}

.new-shipment,
.edit-shipment {
  .form-wrapper-standard {
    // overflow-y: auto;
    padding-right: 1rem;
    .input__text-select {
      .input {
        flex-basis: 155px !important;
      }
    }
    .routes {
      width: 100%;
    }
  }
  .form-line-full {
    overflow-y: auto;
    max-height: 40vh;
  }
  .routes-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px 8px;
    margin-bottom: 16px;

    .line-divider {
      width: 100%;
      height: 1px;
      background-color: #e5e5e5;
    }

    .transport-container {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .single-input-transport {
      width: 100%;
    }

    .single-transport-container {
      width: 49%;
    }

    .column {
      flex-basis: calc(50% - 8px);
      flex-grow: 1;
      h3 {
        margin-top: 0px !important;
        margin-bottom: 8px;
      }
      .origin-destination {
        .add-route {
          margin-top: 9px;
          cursor: pointer;
          display: inline-flex;
          justify-content: space-between;
          column-gap: 7px;
          p {
            margin: 0 !important;
          }
        }
      }
      .transport {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 8px;
        .icon-wrapper {
          cursor: pointer;
        }
      }
    }
  }
  .routes-wrapper:last-child {
    margin-bottom: 0 !important;
  }
  .error-text {
    margin-top: 8px;
  }
}

.delete-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
