

.summary-text {
    margin-left: 2.33em;
}

.summary-steps-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the minmax and width as needed */
    grid-gap: 10px; /* Adjust the gap between divs */
}