@import 'src/styles/colorVariables';
.slide-toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .slide-toggle {
    width: 25px;
    height: 14px;
    box-sizing: border-box;
    input.status {
      display: none;
    }
    // Unchecked State
    .status-switch {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: relative;
      background-color: $input-bg-color;
      color: white;
      transition: all 0.5s ease;
      padding: 1px 0px;
      border-radius: 7px;
      box-sizing: border-box;

      .left-check,
      .right-check {
        border-radius: 50%;
        height: calc(100% - 2px);
        width: calc(50% - 1px);
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: center;
        box-sizing: border-box;
      }

      .left-check {
        left: 1px;
        animation: to-left 0.3s;
      }

      .right-check {
        right: 1px;
        animation: to-right 0.3s;
      }

      .disabled {
        .left-check {
          background: $gray-color !important;
        }
        .right-check {
          left: 1px;
        }
      }
    }

    // Checked state
    &:checked > .status-switch {
      background-color: $input-bg-color;

      .right-check {
        animation: to-right 0.3s;
      }
    }

    &:checked > .disabled {
      background-color: $input-bg-color;

      .left-check {
        background: $gray-color !important;
        left: 1px !important;
      }

      .right-check {
        right: 1px !important;
      }
    }
  }
  .tooltip {
    margin-left: 6px;
  }
}

@keyframes to-right {
  0% {
    left: 1px;
  }
  100% {
    left: 50%;
    right: 1px;
  }
}

@keyframes to-left {
  0% {
    left: 50%;
  }
  100% {
    left: 1px;
  }
}
