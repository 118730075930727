@import 'src/styles/colorVariables';

.input {
  &__phone {
    border-radius: 5px;
    border: 1px solid;
    align-items: center;
    .input {
      background: transparent;
    }

    .divider-line {
      width: 1px;
      background-color: $gray-color;
      height: 47.5px;
      position: relative;
    }
    .divider-line--phone {
      width: 1px;
      background-color: $gray-color;
      height: 47.5px;
      position: relative;
      height: 21.5px;
      margin-left: 20px;
    }
    &__content {
      border: none;
      padding: 14px;
      width: 100%;
      height: 16px;
      appearance: none;
      flex: 0 1 48%;
    }
  }
}
