.card-navigation {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .card-navigation-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    h5 {
      margin-top: auto;
      margin-bottom: auto;
    }
    p {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  button {
    width: 127px !important;
  }
}
