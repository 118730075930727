@import 'src/styles/mediaQueries';
@import 'src/styles/mixins';

.company-environment-wrapper {
  &__body {
    display: grid;
    grid-template-columns: 1.15fr 0.85fr;
  }

  &__two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;

    .button-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      width: 100%;
      margin-top: 1rem;

      @include break($small) {
        margin-top: 1rem;
      }
    }
  }

  &__two:first-child {
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }

  &__two:last-child {
    padding-right: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;

    @include break($small) {
      padding-right: 1rem;
    }
  }
}

.company-environment-wrapper__body {
  .comparison-wrapper {
    display: flex;
    gap: 0.5rem;
    height: 25%;

    &__item,
    .tag-wrapper {
      max-width: 10rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.33rem;
    }

    .title span {
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }

  .company-environment-scopes__information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    @include break($small) {
      width: 95%;
      margin-left: -10px;
    }
    gap: 0.5rem;
    > div {
      width: 100%;
      .tag-wrapper {
        display: flex;
        width: 100%;
        .tag-elem:first-child {
          padding-right: 1rem;
        }
      }
    }
  }

  .co2-wrapper {
    padding: 1rem;
  }
  .co2-progress-bar {
    position: relative;
    height: 100%;
    width: 9.5rem;
    box-sizing: border-box;

    .co2-information {
      position: absolute;
      top: 35%;
      left: 26%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      .co2-value {
        text-align: center;
      }
      .co2-units {
        text-align: center;
      }
    }
  }
}

.download-button {
  margin-left: auto;
}

.download-button button {
  width: auto;
  height: auto;
  padding: 0.25rem;
}
.download-button img {
  width: 1rem;
  height: 1rem;
}

.download-button {
  .download-locked {
    display: flex;
    align-items: flex-end;
    height: 100%;
    cursor: pointer;
    img {
      margin-left: 5px;
    }
  }
}
