@import 'src/styles/colorVariables';
.card-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .create-new-card {
    padding: 8px;
    border-width: 1px;
    border-style: dashed;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    row-gap: 1rem;
    @media (max-width: 1000px) {
      width: 100%;
    }
    h1 {
      margin: 0;
      text-align: center;
    }
    p {
      margin: 0;
      text-align: center;
    }
    .button-wrapper {
      width: 100%;
      align-self: center;
      display: flex;
      flex-direction: column;
      button,
      .nuvo-tailwind {
        width: 100%;
      }
      @media (max-width: 1000px) {
        width: 25%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  // .card,
  // .card-dropdown {
  //   height: 100%;
  // }
  .card {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    width: calc(16.6% - 16px);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    min-height: 12.5rem;
    max-height: 200px;

    @media (max-width: 1000px) {
      width: 100%;
    }

    .button-dropdown {
      cursor: pointer;
      z-index: 5;
      display: block;
      .button-wrapper {
        float: right;
      }
      .button-dropdown-options {
        right: 0 !important;
        left: auto;
      }
    }

    &__content-wrapper {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      height: 100%;
      img {
        cursor: auto !important;
      }
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
      .total-info {
        display: flex;
        align-items: center;
        p {
          margin: 0 0 0 6px;
        }
      }
    }
  }
}

.tooltip-card-measure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  border-radius: 5px;
}

.tooltip-card-measure p {
  margin: 0;
}

.card-list.list {
  flex-direction: column;
  .card {
    height: auto;
    width: 100%;
    .create-new-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      h1 {
        margin-top: 0;
      }
      .button-wrapper {
        width: 100%;
      }
    }
  }
}