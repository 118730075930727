.horizontal-bar-wrapper {
  height: 10px;
  width: 100%;
  display: flex;
  background-color: transparent;
  box-sizing: border-box;

  .bar {
    height: 100%;
    margin-right: -3px;
    border-radius: inherit;
  }
}
