.modeling {
    width: 100%;
    height: 100%;
}

.item-objective {
    grid-area: objective;
}

.item-chart {
    grid-area: chart;
}

.item-table {
    grid-area: table;
}

.modeling-container {
    height: 88%;
    display: grid;
    grid-template-columns: 1fr 2.3fr;
    grid-template-rows: 1fr 1.35fr;
    grid-template-areas: 
        "objective chart"
        "table table";
    gap: 0.5rem;
}
