.front-page {
  .title {
    h1 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
  .content {
    width: 250px;
  }
}
