@import 'src/styles/colorVariables';

.input {
  .multi-select-wrapper {
    display: flex;
    column-gap: 0.5rem;

    .divider-line--small {
      flex: 0 0 1px;
      background-color: $gray-color;
      height: 48px;
    }
    .selected-values {
      display: flex;
      row-gap: 0.5rem;
      column-gap: 0.5rem;
      flex-wrap: wrap;
    }

    .left {
      flex-direction: row-reverse;
    }
  }
  .left {
    justify-content: flex-end;
  }
}

.multi-select-value {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 5px;
  color: $on-tag-text-color;
  border: 1px solid $on-tag-text-color;
  box-sizing: border-box;
  white-space: nowrap;
  background: $tag-bg-color;
  height: 24px;

  img {
    margin-left: 8px;
    width: 10px;
    cursor: pointer;
  }
}
.multi-select-value:last-child {
  margin-right: 0 !important;
}
