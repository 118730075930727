.edit-invoice {
  .form-wrapper {
    margin-bottom: 2rem;
    .input {
      & > .input__text-select {
        .input {
          flex-basis: 155px !important;
        }
      }
    }
  }
  .buttons {
    margin-top: 1.5rem;
  }
  .type-selector {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    span {
      margin-right: 0.25rem;
    }
  }
}
.invoice-pdf-wrapper {
  width: 50%;
  min-width: 450px;
  margin-right: 3rem;
  .edit-file-upload-wrapper {
    width: 100%;
    height: 100%;
  }
}
