.hubs {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 5rem auto 1fr;
  .infinite-scroll-wrapper-card {
    overflow-y: auto;
    margin-top: 2rem;
  }

  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  &__subheader {
    display: flex;
    justify-content: flex-end;
    // justify-content: space-between;
    align-items: center;
    .legend-wrapper {
      border-radius: 8px;
      padding: 0.5rem 1rem;
      border: 1px solid;
    }

    .sfc-logo-container {
      display: flex;
      justify-content: flex-end;
      margin-right: 1em;
    }
  }
}
