.icon-wrapper {
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
  }
}
