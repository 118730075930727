.upload-invoice #nuvo-custom-upload-button {
  width: 100%;
}

#cell-popper-element-id {
  background-color: rgba(237, 112, 112, 1);
  border-color: rgba(237, 112, 112, 1);
}

#arrow-message-info:before {
  border: none;
}
