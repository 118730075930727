.ghg-business-description {
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    align-items: center;
    padding: 8px 7px;
    border-radius: 8px;
    margin-bottom: 8px;
    &__image-wrapper {
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-right: 15px;
      width: 130px;
      min-width: 130px;
      h2 {
        margin-left: 8px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    p {
      word-break: normal;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }
}
