.metric {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  flex-shrink: 0; /* prevent items from shrinking */
  height: auto; /* allow items to have varying heights based on their content */
  box-sizing: border-box;
  &__line {
    width: 5px;
    border-radius: 8px;
    height: 33px;
  }
  .metric-info {
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 0.5rem;
    }
    &__value {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  .label-div {
    height: fit-content;
  }
  .icon-text-wrapper {
    display: flex;
    align-items: center;
    column-gap: 0.2rem;
    img {
      width: 15px;
      height: 15px;
    }
  }
}
