.create-invoice,
.edit-invoice {
  .form-wrapper {
    .input {
      & > .input__text-select {
        .input {
          flex-basis: 155px !important;
        }
      }
    }
    .modern-checkbox-container {
      margin-top: 0.5rem;
    }
  }
  .buttons {
    margin-top: 1.5rem;
  }
  .type-selector {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}
