@import 'src/styles/colorVariables';

.select-options-wrapper {
  position: absolute;
  border: 1px solid;
  border-top: none;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 5px;
  border-radius: 5px;
  background-color: $input-bg-color;
  width: calc(100% + 2px);
  left: -1px;
  top: calc(100% - 12px);
  z-index: 8;
  box-sizing: border-box;
  &.avoid-off-screen {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-top: 1px solid;
    border-bottom: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
