.hide-show-chat {
  position: fixed;

  background-color: black;
  color: white;
  border-radius: 8px;
  padding: 5px 10px;
  text-align: center;
  z-index: 1000;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.on-chat {
  bottom: 65px;
  right: 10px;
}
.below-chat {
  bottom: 10px;
  right: 10px;
}
