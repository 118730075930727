.header-tasks {
  position: relative;
  .tasks-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 19px;
    // height: 23px;
    cursor: pointer;
    // padding: 4px 6px;
    img {
      width: 18px;
      height: 18px;
    }
  }

  .tasks-modal {
    position: absolute;
    padding: 16px 20px;
    border-radius: 5px;
    right: 0;
    top: 40px;
    z-index: 100;
    width: 250px;
    box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
    .tasks-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      h1 {
        margin-top: 0px;
        margin-bottom: 0px;
        max-width: 126px;
      }
    }
    .category-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      .category-tag {
        width: calc(33% - 4px);
        padding: 4px;
        border-radius: 3px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .category-tag:last-child {
        margin-right: 0px !important;
      }
    }
    .tasks-wrapper {
      height: 65px;
      overflow-x: hidden;
      overflow-y: auto;
      .task-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        cursor: pointer;
        .task-item-icon-wrapper {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          .task-item__text {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .overflow-2-lines {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .chevron {
          width: 7px;
          height: 8px;
          margin-right: 9px;
          margin-left: 4px;
        }
      }
      .no-tasks {
        .no-tasks-header {
          display: flex;
          align-items: center;
          img {
            margin-right: 8px;
          }
        }
        .description {
          margin-left: 24px;
        }
      }
    }
    .buttons-task {
      display: flex;
      margin-top: 8px;
      width: 100%;
      button {
        width: 100% !important;
      }
      // button:first-child {
      //   margin-right: 8px;
      // }
    }
  }
}
