.name-employee-report-table {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.name-employee-report-table img{
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
}