.events {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  .infinite-scroll-wrapper-card {
    overflow-y: auto;
    margin-top: 2rem;
  }

  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
