.opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.3);
    mix-blend-mode: hard-light;
    overflow: hidden;
}

.no-opacity {
    position: absolute;
    border-radius: 4px;
    background-color: gray;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s ease 0s;
    overflow: hidden;
}

// MODAL

.modal-no-opacity {
    position: absolute;
    z-index: 10010;
    border-radius: 3px;
    overflow: hidden;
}

// INSIDE MODAL

.tutorial-modal-wrapper {
    padding: 1em 1.5em;
}