@import 'src/styles/colorVariables.scss';
.button-arrow {
  background-color: $icon-bg-color;
  padding: 5px;
  border-radius: 50%;
  //   all: unset;
  border: none;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-arrow:hover {
  cursor: pointer;
}

.button-arrow.left {
  transform: rotate(0.5turn);
}

.button-arrow.up {
  transform: rotate(0.75turn);
}

.button-arrow.down {
  transform: rotate(0.25turn);
}
