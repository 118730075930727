.budget-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  img {
    // height: 16px;
    max-width: 24px;
  }
  label {
    margin-bottom: 0;
  }
  .input {
    // margin-left: auto;
    max-width: 100px;
  }
}
