.companies {
  &__body {
    display: flex;

    flex-wrap: wrap;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    .companies-card {
      // margin: 10px;
      padding: 13px 13px 18px 13px;
      border-width: 1px;
      border-style: dashed;
      border-radius: 5px;
      box-sizing: border-box;
      width: calc(100% - 20px);

      h1 {
        margin: 0 auto 6px auto;
        text-align: center;
      }

      p {
        margin: 0 auto 16px auto;
        text-align: center;
      }

      .buttons {
        display: flex;
        justify-content: center;
        button {
          max-width: 120px;
          margin-right: 8px;
        }
        button:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .companies-wrapper {
    border-radius: 5px;
    // margin-top: 12px;
    padding: 33px 36px;
    overflow: auto;
    height: 100%;
    .icon-text-wrapper {
      img {
        margin-right: 12px;
      }
    }
    table {
      colgroup {
        width: calc((100% / 7));
      }
      tbody {
        tr {
          td:first-child {
            padding-right: 26px !important;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
          td:last-child {
            margin-right: 0 !important;
            .button-dropdown {
              justify-content: flex-end !important;
              .button-dropdown-options {
                right: 0 !important;
              }
            }
            .company-edit {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              img {
                height: 15px;
                width: 15px;
              }
              .img-margin-right {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 25px;

      .table-filters {
        display: flex;
        .input {
          margin-right: 9px;
          min-width: 120px;
          flex: 0 0 0;
        }
        .calendar-range {
          width: 210px;
          min-width: 210px;
        }
        .multi-select {
          .input__select {
            min-width: 120px;
          }
        }
      }
      .total-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .corner-position {
    border-radius: 3px;
    display: inline-block;
    padding: 2px 5px;
    margin: auto;
  }
}
