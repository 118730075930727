.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 1rem;
  .file-icon-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 1rem;
    img {
      width: 12px;
    }
    img.download {
      cursor: pointer;
    }
  }
}
