@import 'src/styles/colorVariables';
.notification-content {
  border: 1px solid $gray-color2;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: $gray-dashboard;
  box-sizing: border-box;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  width: 100%;
}
.notification-content:hover {
  border: 1px solid $tag2-bg-color;
  .title {
    text-decoration: underline;
  }
}
