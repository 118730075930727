@import 'src/styles/colorVariables.scss';
.steps {
  &__content {
    margin-top: 20px;
    margin-left: 15px;
    border-left: 1px solid $gray-color2;
    padding-left: 33px;
    width: 300px;
  }

  &__footer {
    width: 100%;
    margin-top: 8px;
    height: 50%;
  }
}

@media screen and (max-width: 480px) {
  .employee-qr {
    transform: scale(0.8);
  }

  .step-page-section {
    padding: 0 !important;
  }

  .step-page-section__logo {
    visibility: hidden;
  }
}

.button-employee-form {
  margin-top: 3em;
}
