.uploaded-files {
    width: 100%;
    height: 100%;
}

.item-header {
    grid-area: header;
    margin-top: 0.66rem;
}

.item-table {
    grid-area: table;
}

.uploaded-files-container {
    height: 88%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 12fr;
    grid-template-areas: 
        "header"
        "table";
    gap: 0.5rem;

}