@import 'src/styles/colorVariables';
.card-cta-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  gap: 1rem;
  text-align: center;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    button {
      width: fit-content !important;
    }
  }
}
