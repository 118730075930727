@import 'src/styles/colorVariables';

.methodology-report {
  display: flex;
  .content {
    margin-right: 74px;
    .title {
      margin-top: 65px;
      margin-bottom: 23px;
      display: flex;
      align-items: center;
      .border-left {
        width: 4px;
        border-radius: 5px;
        height: 32px;
        background-color: $primary-light-color;
      }
      h1 {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 13px;
      }
    }
    .description {
      width: 252px;
      opacity: 0.8;
    }
  }
  img {
    width: 50%;
  }
}
