.pie-chart-distribution {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  .co2-progress-bar {
    position: relative;
    height: 115px;
    width: 115px;
    box-sizing: border-box;
  }

  &__legends-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    justify-content: center;
    .item {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      &__color {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
      }
    }
  }
}
