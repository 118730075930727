.create-organization-holding {
  width: 100vw;
  display: flex;
  justify-content: center;
  .create-organization-holding-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32px 116px;
    max-width: calc(1440px - 232px);
    width: 100%;
    &__logo {
      width: 114px;
      height: 38px;
      align-self: flex-start;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 86px;
      width: 680px;
      h1 {
        text-align: center;
        margin: 0;
      }
      h3 {
        text-align: center;
        margin-top: 16px;
        margin-bottom: 48px;
        width: 507px;
      }
      h3.create-password {
        width: 680px !important;
      }
    }
    // Register form
    &__register-form {
      width: 506px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0px 8px;
      padding: 1rem;
      border-radius: 8px;
      .input {
        margin-bottom: 16px;
      }
      .input-element {
        flex-basis: calc(50% - 8px);
        flex-grow: 1;
        margin-bottom: 16px;
      }
      .input-element {
        .input {
          width: 100% !important;
          margin-bottom: 8px;
        }
      }
      .input--basic {
        width: auto !important;
      }
      .newsletter-checkbox {
        margin-bottom: 12px;
      }
      &__privacy-policy-text {
        display: flex;
        align-items: center;
        span {
          margin-right: 3px;
        }
      }

      &__footer {
        justify-content: center;
        align-items: center;
        width: 506px;
        margin-top: 24px;
      }
    }
  }
}
