.bar-chart-comparison {
  display: flex;
  height: 100%;
  max-width: 100%;
  margin-top: 0.5rem;
  width: 100%;
  max-height: 90%;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
