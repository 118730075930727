@import 'src/styles/colorVariables.scss';

.list-wrapper-filter {
  width: 30%;
}
.list-wrapper {
  height: calc(40vh - 5em);
  max-height: 400px;
  overflow-y: auto;

  @media (max-width: 85rem) {
    height: calc(75vh - 5em);
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white;
    margin-bottom: 1rem;
  }
  th {
    padding-bottom: 0.5rem;
  }

  .company {
    display: flex;
    align-items: center;

    img {
      width: 1em;
      margin-right: 1em;
    }
  }

  .redirect {
    margin-left: 1em;
    margin-right: 0.33xem;

    img {
      width: 1em;
    }
  }

  .environmental-debt {
    padding: 0.5em;
    border-radius: 3px;
    font-size: 9px;
  }
}

.dashboard-card__body--list {
  margin-left: 0.33rem;
  margin-right: 0.33rem;

  colgroup {
    // width: 25%;
  }
  .text-center {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
  }
}
