.companies {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 4.5rem auto auto 1fr;
  gap: 0.5rem;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }
}
