@import '../../../../src/styles/colorVariables';

.offset {
    width: 100%;
    height: 100%;
    position: relative;
}

.offset-container {
    height: 85%;
    display: grid;
    grid-template-rows: 1fr 1.2fr;
    gap: 2rem;
    padding: 1.5rem 3rem;
}

.split-line {
    background-color: $gray-color2;
    border: none;
    height: 1px;
}

.first-row {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
}

.second-row {
    display: flex;
    gap: 1rem;
}