.upload-invoice {
  width: 100%;
  min-width: 485px;
  .files-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 82px;
    overflow-y: auto;
    gap: 1rem;
  }
  .file-upload-wrapper {
    input[type='file'] {
      display: none;
    }
  }
}
.invoice-pdf-wrapper {
  width: 50%;
  min-width: 450px;
  .edit-file-upload-wrapper {
    width: 100%;
    height: 100%;
  }
}
