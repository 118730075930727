.measure-detail-report .header-wrapper {
  display: flex;
  //   flex-direction: column;
}

.measure-detail-report .header-wrapper div:last-child {
  margin-left: auto;
}

.line-chart-wrapper {
  padding: 0.5rem;
  border-radius: 8px;
}

.line-chart {
  grid-area: chart;
}

.rest-info-wrapper {
  grid-area: info;
  display: grid;
  gap: 0.5rem;
}

.warning-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  border-radius: 8px;
  box-sizing: border-box;
  p {
    line-height: 1.4;
    margin: 0;
  }
  img {
    vertical-align: middle;
    height: 0.6rem;
    margin-right: 0.3rem;
  }
}

.right-side {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}
