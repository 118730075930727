.premium-feature-advise {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  row-gap: 24px;
  h1 {
    text-align: center;
    margin: 0;
  }
  p {
    text-align: center;
    margin: 0;
  }
  .buttons {
    display: flex;
    align-items: center;
    width: 80%;
    column-gap: 8px;
  }
}
