@import 'src/styles/fontVariables';

.line-chart-wrapper {
  padding: 20px 12px;
  border-radius: 8px;
  margin-bottom: 7px;
  .legend-wrapper {
    display: flex;
    align-items: center;
    .legend {
      display: flex;
      align-items: center;
      margin-right: 11px;
      img {
        margin-right: 7px;
      }
    }
  }
  .chart-wrapper {
    height: 150px;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-top: 8px;
    .legends-wrapper-chart {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .legend-chart {
      white-space: nowrap;
    }

    * {
      font-family: $font-regular !important;
    }
    canvas {
      margin-top: 8px;
      height: 100% !important;
      width: 100% !important;
    }
  }
}
