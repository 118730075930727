.snippet {
  height: 100%;
  position: relative;

  button {
    width: 120px !important;
    margin-top: 2rem;
  }
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  &__mode {
    width: fit-content;
    position: absolute;
    right: 3rem;
    top: 3rem;
  }
  .snippet-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
  }
  .snippet-downloadable {
    padding: 24px;
    border: 1px solid;
    border-radius: 5px;
    h4 {
      margin-top: 0px;
      margin-bottom: 32px;
    }

    .dot {
      margin-right: 0.25rem !important;
    }

    .company-environment-wrapper-downloadable {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 3rem;

      .company-environment-impact {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-right: 48px;
        .offset {
          margin-top: 16px;
          text-align: center;
          margin-right: 0 !important;
        }
        &__co2-wrapper {
          width: 194px;
          height: 194px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding: 18px;
          @media (max-width: 1300px) {
            margin-left: 0px;
          }

          .co2-progress-bar {
            position: relative;
            height: 167px;
            width: 167px;
            box-sizing: border-box;

            .co2-information {
              position: absolute;
              top: 35%;
              left: 26%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 50%;
              .co2-value {
                text-align: center;
              }
              .co2-units {
                text-align: center;
              }
            }
          }
        }
      }

      .company-environment-scopes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .button-wrapper {
          width: 100%;
          margin-top: auto;
          display: flex;
          flex-direction: column;
          position: relative;
          button:first-child {
            margin-bottom: 8px;
          }
          span {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: -20px;
          }
        }
        &__information {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
          @media (max-width: 1300px) {
            margin-right: 16px;
          }
          h4 {
            margin-top: 0px;
            margin-bottom: 16px;
            text-align: center;
          }
          h5 {
            margin-top: 0px;
            margin-bottom: 24px;
            text-align: center;
          }
          .tooltip {
            width: 100%;
          }

          .tag-wrapper {
            margin-bottom: 10px;
            width: 100%;
            box-sizing: border-box;
            justify-content: space-between;
          }
          .tag-wrapper:last-child {
            margin-bottom: 0 !important;
          }
          .extra-info {
            display: flex;
            align-items: center;
            strong {
              margin-left: 3px;
              margin-right: 3px;
            }
            img {
              width: 26px;
              height: 8px;
              margin-left: 4px;
            }
          }
        }

        .comparison-wrapper {
          display: flex;
          margin-top: 21px;
          width: 100%;
          column-gap: 8px;
          &__item,
          .tag-wrapper {
            max-width: 10rem;
            display: flex;
            flex-direction: column;
            row-gap: 0.33rem;
          }
        }
      }
    }

    .snippet-footer {
      margin-top: 35px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 38px;
        height: 13px;
      }
      .data-via {
        margin-left: 30px;
        margin-right: 2px;
      }
      .powered-by {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        column-gap: 0.5rem;
      }
    }
  }
}
