.profile {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4.5rem auto;
  height: 100%;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 0px;
    }
    h4 {
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px 24px;
    @media (max-width: 1308px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      max-height: 100vh;
      overflow-y: auto;
    }

    .card-profile-info {
      border-radius: 5px;
      padding: 1rem 2rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .input-photo {
        align-self: center;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        h1 {
          margin-top: 0;
          margin-bottom: 0;
        }
        img.edit {
          width: 18px;
        }
      }

      .form-wrapper-standard {
        margin-bottom: 0 !important;
      }
    }

    .navigation-wrapper {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      row-gap: 16px;
    }
  }
}
