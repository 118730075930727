.co2-info-emissions {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  .icon-wrapper {
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 3px;

    img {
      width: 10px;
      height: 10px;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
    .value-wrapper {
      display: flex;
      column-gap: 0.2rem;
    }
  }
}
