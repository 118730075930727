@import 'src/styles/colorVariables';
@import 'src/styles/fontVariables';
.vehicles {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 5rem auto 1fr;

  .sorting-wrapper {
    margin-right: 8px;
  }

  .infinite-scroll-wrapper-card {
    overflow-y: auto;
    margin-top: 2rem;
  }

  &__header {
    width: 80%;

    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  &__subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .legend-wrapper {
      border-radius: 8px;
      padding: 0.5rem 1rem;
      border: 1px solid;
      gap: 8px;
      display: flex;
      align-items: center;
    }

    &__filters {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}

.delete-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.new-vehicle {
  height: 100%;
  display: flex;
  flex-direction: column;
  .vehicle-type {
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
    }
    .types {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 26px;
    }
  }
  .form-wrapper-standard {
    margin-top: 0;
    .input-element {
      .checkbox-margin {
        margin-top: 10px;
      }
    }
  }

  .buttons {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

#vehicles .card-list .create-new-card {
  border-style: none;
  padding: 0px;
  width: 100%;
}
