@import 'src/styles/fontVariables';
.electricity-emissions {
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 9px;
    .list-header {
      padding: 8px 7px !important;
    }
    &__item {
      border-radius: 8px;
      padding: 10px 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text-icon-wrapper {
        display: flex;
        align-items: center;
        .icon-wrapper {
          width: 20px;
          height: 20px;
          padding: 5px;
          border-radius: 3.75px;
          box-sizing: border-box;
          margin-right: 8px;
          img {
            width: 10px;
            height: 10px;
          }
        }
        span {
          width: calc(100%);
        }
      }
      > * {
        width: calc((100% / 5));
      }
      > span {
        text-align: center;
      }
      .vehicle-name {
        text-align: left;
        padding-left: 28px;
        width: calc((100% / 5) - 28px);
        white-space: nowrap;
      }
    }
  }
}
