@import 'src/styles/colorVariables';

.loader-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  h1 {
    margin-top: 0;
    margin-bottom: 50px;
  }
  img {
    position: absolute;
    top: calc(50% + 20px);
    width: 50px;
    height: 50px;
  }
}
.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.loader span:nth-child(1) {
  filter: blur(1.7px);
}

.loader span:nth-child(2) {
  filter: blur(3.3px);
}

.loader span:nth-child(3) {
  filter: blur(8.4px);
}

.loader span:nth-child(4) {
  filter: blur(16.7px);
}

.loader:after {
  content: '';
  position: absolute;
  top: 3.3px;
  left: 3.3px;
  right: 3.3px;
  bottom: 3.3px;
  background: $gray-dashboard;
  border: solid $white 3.3px;
  border-radius: 50%;
}
