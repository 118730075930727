.invoice-pdf-wrapper {
  width: 50%;
  min-width: 450px;
  .edit-file-upload-wrapper {
    width: 100%;
    height: 100%;
  }
  .change-document-wrapper {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    img.chevron-icon-left {
      transform: rotate(90deg);
      cursor: pointer;
    }
    img.chevron-icon-right {
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }
}
