@import 'src/styles/colorVariables';

.notifications-modal {
  position: absolute;
  right: 0;
  top: 40px;

  z-index: 100;

  padding: 2rem;

  border-radius: 0.5rem;
  border: 1px solid $gray-color2;

  box-sizing: border-box;
  box-shadow: 0px 4px 10px 4px rgba(110, 134, 247, 0.03), 0px 8px 6px 2px rgba(110, 134, 247, 0.05),
    0px 0px 2px -4px rgba(110, 134, 247, 0.08);

  min-width: 541px;
}
