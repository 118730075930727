@import 'src/styles/colorVariables';

.measure-main {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 4.5rem 1fr;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 0px;
    }
    h4 {
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
}
