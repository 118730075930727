@import '../../../../styles/colorVariables.scss';

.report-table {
  border-collapse: collapse;
}

.report-table-wrapper {
  background-color: $gray-dashboard;
  border-radius: 5px;
}
.report-table-header tr {
  line-height: 120%;
}

.report-table-header > tr > th,
.report-table-wrapper td {
  text-align: left;
}

.report-table-header > tr th:last-child {
  text-align: center;
  padding-right: 0.5rem;
}

.report-table-wrapper td {
  padding: 0 0.5rem;
}
