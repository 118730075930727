.user-invited-tag {
  display: inline-flex;
  align-items: center;
  border-radius: 30px;
  padding: 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  img {
    margin-left: 4px;
    cursor: pointer;
  }
}
