.sfc-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1em;

  p {
    font-size: 0.8em;
    margin-right: 0.8em;
  }
}
