.form-wrapper-standard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 8px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .input,
  .input-element {
    width: 100%;
    flex-basis: calc(50% - 8px);
    flex-grow: 1;
    @media (max-width: 768px) {
      flex-basis: 100%;
    }
  }
  /* max-height: 60vh;
  overflow-y: auto; */
}
