.validation {
  &__wrapper {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 11px;
  }
  &__list {
    padding: 1rem;
  }
}
