.select-type-wrapper {
    background-color: rgb(255, 255, 255);
    align-items: center;
    padding: 1rem 1.66rem;
    border-radius: 8px;
    margin: 2rem 16%;

    .select-card-section {
        margin: 0
    }
}