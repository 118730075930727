@import 'src/styles/colorVariables';

.input {
  &__calendar {
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
    position: relative;

    .small {
      .image-wrapper {
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }
    .input {
      max-width: 108px;
      min-width: 108px;
      .input__select {
        border-radius: 0px !important;
        border-left: none;

        .select-options {
          max-height: 185px !important;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border: none;
      padding: 0px;
      width: 100%;
      column-gap: 0.4rem;
      .divider-line--small {
        width: 1px;
        background-color: $gray-color;

        position: relative;
      }
      &__date {
        display: flex;
        overflow: hidden;
        img {
          cursor: pointer;
        }
      }
      input[type='text'] {
        border: none !important;
        height: 100% !important;
        text-align: center !important;
      }
    }

    .calendar-positioner {
      position: absolute;
      top: calc(100% + 0.5rem);
      left: 0;

      &__end-date {
        left: 50%;
      }
    }

    .calendar-wrapper {
      position: fixed;
      z-index: 8;
      border: 1px solid $gray-color2;
      border-radius: 5px;
      background: $input-bg-color;
      // .rdrDateRangePickerWrapper {
      //   border: 1px solid $gray-color2;
      //   border-radius: 5px;
      // }
      .rdrDateInput {
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button.primary-button {
        width: 20% !important;
        margin-left: auto;
        height: 30px;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
    .calendar-positioner.top {
      top: -320px;
    }
  }
}
