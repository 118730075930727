.facilities-distribution {
  .facilities-distribution-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;

    .facilities-distribution-categories {
      width: 50%;

      .card-info-rows {
        row-gap: 0.5rem;
        width: 100%;
        flex-direction: column;
        align-items: flex-start !important;
        margin-bottom: 1rem !important;
      }
      .stacked-bar-wrapper {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        width: 100%;
        .stacked-bar {
          max-width: 100% !important;
          min-width: auto !important;
          flex: 1;
        }
      }
    }
  }
  .card-info-co2 {
    justify-content: center;
    flex-direction: column;
    row-gap: 1rem;
    .scopes-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .tag-wrapper {
        width: 100%;
      }
    }
  }
}
