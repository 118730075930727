@import '../../../../styles/colorVariables.scss';

.list-wrapper-orgs {
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;
  background-color: $white;
  thead {
    position: sticky;
    top: -1rem;
    z-index: 1;
    background-color: $white;
    margin-bottom: 1rem;
  }
  th {
    padding-bottom: 0.5rem;
  }

  tbody {
    tr {
      td {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
    }
  }

  tfoot {
    position: sticky;
    bottom: -1rem;
    z-index: 1;
    background-color: $white;
  }

  .company {
    display: flex;
    align-items: center;

    img {
      width: 1em;
      margin-right: 1em;
    }
  }

  .redirect {
    margin-left: 1em;
    margin-right: 0.33xem;

    img {
      width: 1em;
    }
  }

  .environmental-debt {
    padding: 0.5em;
    border-radius: 3px;
    font-size: 9px;
  }
  .text-center {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
  }
}
