@import 'src/styles/utils';

.modal-wrapper-base {
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  .modal {
    padding-top: 72px;
    padding-bottom: 48px;
    padding-inline: 48px;

    // Old modal padding
    /* padding: 33px; */
    //
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    max-height: 100%;
    border-radius: 8px;
    position: relative;
    /* margin-left: 166px; */ // center in content, margin left is width of side menu
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    box-sizing: border-box;
  }
}

.modal-wrapper {
  @extend .modal-wrapper-base;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-wrapper-with-portal {
  @extend .modal-wrapper-base;
  position: relative;
  min-height: 100svh;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-content {
  overflow-y: auto;
  max-height: 50svh;
}

.modal-buttons {
  @extend .flex, .gap-x-2;
  margin-top: 1rem;
}
