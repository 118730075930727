@import 'src/styles/colorVariables';
@import 'src/styles/fontClasses';

.notification-bubble {
  background-color: $notification-color;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
