.event-detail-table {
  border-radius: 5px;
  margin-top: 12px;
  padding: 33px 36px;
  .buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    button {
      // width by content of the button
      width: fit-content !important;
    }
  }
}
