@import '../../../styles/colorVariables.scss';

/* Sets the width for the accordion. Sets the margin to 90px on the top and bottom and auto to the left and right */

.accordion {
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
  h1 {
    text-align: center;
  }
  .container {
    position: relative;
  }
  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &.justify-start {
      justify-content: start;
      gap: 16px;
    }
  }
  .content {
    position: relative;
    max-height: 0;
    text-align: justify;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
    &.active {
      background-color: transparent;
      color: $on-light-text-color;
      max-height: 315px;
      overflow-y: auto;
    }
  }

  hr {
    width: 100;
    margin-left: 0;
    border: 1px solid grey;
  }
}
