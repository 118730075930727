@import 'src/styles/fontVariables';
.emissions {
  position: relative;
  .uncertainty-level {
    border-radius: 8px;
    padding: 1rem;
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    position: absolute;
    right: 0;
    top: -50px;
  }
  .card-info-co2 {
    border-radius: 8px;
    padding: 30px 24px 30px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 13px;
    .co2-progress-bar {
      position: relative;
      height: 115px;
      width: 115px;
      box-sizing: border-box;

      .co2-information {
        position: absolute;
        top: 39%;
        left: 26%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        .co2-value {
          text-align: center;
        }
        .co2-units {
          text-align: center;
        }
      }
    }
    .scopes-wrapper {
      margin-left: 15px;
      .tag-wrapper {
        background: transparent !important;
        justify-content: space-between;
        // > div {
        //   margin: 0;
        // }
      }
    }
  }
  .emissions-info {
    display: flex;
    margin-bottom: 40px;
    .co2-emissions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 13px;
      border-radius: 8px;
      flex-grow: 1;
    }

    .co2-emissions:first-child {
      margin-right: 8px;
    }
  }
  .emissions-evolution {
    margin-top: 11px;
    padding: 12px;
    .scopes {
      display: flex;
      margin-bottom: 1rem;
      .scope {
        display: flex;
        align-items: center;
        img {
          margin-right: 7px;
          width: 6px;
          height: 6px;
        }
        span {
          margin-right: 11px;
        }
      }
    }
  }
}
