.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}
// ###### NOT WORKING #######
// @keyframes pulse {
//   0% {
//     transform: scaleZ(1);
//   }
//   50% {
//     transform: scale3d(1.05, 1.05, 1.05);
//   }
//   to {
//     transform: scaleZ(1);
//   }
// }
// .pulse {
//   animation-name: pulse;
// }
@keyframes rubberBand {
  0% {
    transform: scaleZ(1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scaleZ(1);
  }
}
.rubberBand {
  animation-name: rubberBand;
}
@keyframes shake {
  0%,
  to {
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  animation-name: shake;
}
@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    transform: none;
  }
}
.wobble {
  animation-name: wobble;
}
@keyframes jello {
  0%,
  11.1%,
  to {
    transform: none;
  }
  22.2% {
    transform: skew(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skew(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skew(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skew(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skew(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skew(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skew(-0.1953125deg) skewY(-0.1953125deg);
  }
}
.jello {
  animation-name: jello;
  transform-origin: center;
}
