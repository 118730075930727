.infotag {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 1.1em;
    line-height: 1.6em;
  }
}

.infotag > div {
  margin: 1.2em 0;
}

.link-word {
  text-decoration: underline;
  margin-left: 0.2em;
}
