.organization-co2-info {
  display: flex;
  column-gap: 16px;
  height: 30%;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: 85rem) {
    flex-direction: column;
    height: auto;
  }

  .card:last-child {
    margin-right: 0px;
  }

  .card:first-child {
    margin-left: 0px;
  }

  .card {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    box-sizing: border-box;
    flex: 1 1 auto;
    width: 50%;
    @media (max-width: 85rem) {
      width: 100% !important;
      height: 33%;
      margin: 0 !important;
    }

    &__tooltip {
      position: absolute;
      right: 18px;
      top: 14px;
    }
    .card-info-piechart {
      width: calc(100% - 131px) !important;
      margin-right: 25px;
    }
    .card-info {
      width: 78% !important;
      margin-right: 35px;
    }
    .card-column {
      width: 50%;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 13px;
    }
    p {
      margin-top: 0;
      margin-bottom: 12px;
    }
    .percentage-tag,
    .text-tag {
      display: inline-block;
      padding: 2px 5px;
      border-radius: 3px;
    }
    .bars-wrapper {
      display: flex;
      align-items: flex-end;
      margin-top: 9px;
      img {
        margin-left: 9px;
      }
    }
    .scopes-info {
      display: flex;
      align-items: center;
      column-gap: 2px;
      .tag-wrapper {
        padding: 0.33rem;
      }
    }
    .co2-wrapper {
      width: 103px;
      height: 103px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0.5rem;

      .co2-progress-bar {
        position: relative;
        height: 120px;
        width: 120px;
        box-sizing: border-box;

        .piechart-co2-information {
          position: absolute;
          top: 35%;
          left: 26%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          .co2-value {
            text-align: center;
          }
          .co2-units {
            text-align: center;
          }
        }
      }
    }
    .co2-information {
      display: flex;
      align-items: flex-end;
      span {
        margin-left: 6px;
        margin-bottom: 2px;
      }
    }

    .half-co2-wrapper {
      width: 150px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-radius: 100px 100px 0 0;

      .half-co2-progress-bar {
        position: relative;
        height: 120px;
        width: 120px;
        box-sizing: border-box;

        .half-piechart-co2-information {
          position: absolute;
          top: 50%;
          left: 26%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          .co2-value {
            text-align: center;
          }
          .co2-units {
            text-align: center;
            white-space: nowrap;
          }
        }
      }
    }
    .half-co2-information {
      display: flex;
      align-items: flex-end;
      span {
        margin-left: 6px;
        margin-bottom: 2px;
      }
    }
  }
}
