.methodology-scope-period {
  display: flex;
  flex-direction: column;
  &__item:first-child {
    margin-bottom: 4px;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 8px 7px;
    border-radius: 8px;
    // margin-bottom: 4px;
    &__image-wrapper {
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-right: 15px;
      h2 {
        margin-left: 8px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .columns-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 125px;
      width: 100%;
      p:last-child {
        margin-right: 8px;
      }
    }
  }
  &__scopes {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      flex-direction: column;
      // margin-bottom: 4px;
      &__content {
        display: flex;
        align-items: center;
        padding: 5px 11px;
        border-radius: 8px;
        &__image-wrapper {
          display: flex;
          align-items: center;
          align-self: flex-start;
          margin-right: 15px;
          width: 170px;
          min-width: 170px;
          .icon-wrapper {
            width: 11px;
            height: 11px;
            border-radius: 3px;

            img {
              width: 8px;
              height: 8px;
            }
          }
          h2 {
            margin-left: 8px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .measured {
          margin-left: 90px;
          width: 8px;
        }
        .not-measured {
          width: 8px;
          margin-left: auto;
          margin-right: 20px;
        }
      }
      &__categories {
        display: flex;
        flex-direction: column;
        &__item {
          display: flex;
          align-items: center;
          padding: 3px 11px 3px 35px;
          border-radius: 8px;
          margin-bottom: 4px;
          &__image-wrapper {
            display: flex;
            align-items: center;
            align-self: flex-start;
            margin-right: 15px;
            width: 170px;
            min-width: 170px;
            .icon-wrapper {
              width: 11px;
              height: 11px;
              border-radius: 3px;

              img {
                width: 8px;
                height: 8px;
              }
            }
            h2 {
              margin-left: 8px;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
          .measured {
            margin-left: 66px;
            width: 8px;
          }
          .not-measured {
            width: 8px;
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
