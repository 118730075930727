@import 'src/styles/colorVariables';
.name-icon--small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid $white;
  cursor: pointer;
}
.name-icon--big {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px !important;
  height: 60px !important;
  border: 1px solid $white;

  cursor: pointer;
}
.name-icon--medium {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px !important;
  height: 26px !important;
  border: 1px solid $white;

  cursor: pointer;
}
