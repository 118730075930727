.impact-report {
  display: flex;
  height: 100%;
  margin-top: 12px;
  column-gap: 20px;
  .card {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    width: 50%;
    padding: 24px 19px;

    h2 {
      margin-top: 0;
      margin-bottom: 6px;
    }

    .card-info-co2 {
      border-radius: 8px;
      padding: 9px 12px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 13px;
      .co2-progress-bar {
        position: relative;
        width: 150px;
        box-sizing: border-box;

        .co2-information {
          position: absolute;
          top: 38%;
          left: 26%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;

          .co2-value {
            text-align: center;
          }
          .co2-units {
            text-align: center;
          }
        }
      }
      .scopes-wrapper {
        margin-left: 1rem;
        .tag-wrapper {
          background: transparent !important;
        }
      }
    }
    .card-info-columns {
      margin-top: 6px;
      margin-bottom: 8px;
      display: flex;
      span:first-child {
        width: 54%;
      }
      span {
        width: 26%;
      }
      span:last-child {
        margin-left: auto;
        width: 15%;
      }
    }
    // .card-footer {
    //   margin-top: 20px;
    //   margin-bottom: 21px;
    // }

    .rows-wrapper {
      padding: 12px;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .card-info-rows {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .name-logo {
          display: flex;
          align-items: center;
          width: 40%;
          img {
            margin-right: 8px;
            margin-top: 0 !important;
            width: 15px;
            max-height: 10px;
          }
        }

        span:last-child {
          margin-left: auto;
          width: 12%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 2px;
          border-radius: 12px;
          margin-left: 5%;
        }
        .legend {
          display: flex;
          align-items: center;
          margin-right: 8px;
          .dot {
            width: 6px;
            height: 6px;
            margin-right: 6px;
          }
        }
      }
      .card-info-rows:last-child {
        margin-bottom: 0 !important;
      }

      .bar-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: fit-content;
      }
    }
    .comparative-wrapper {
      display: flex;
      row-gap: 1rem;
      flex-direction: column;
      .comparative-column {
        width: fit-content;
        .comparative {
          display: flex;
          width: 100%;
          align-items: center;
          padding: 13px 8px;
          border-radius: 8px;
          box-sizing: border-box;
          column-gap: 1rem;
          .icon-text-wrapper {
            img {
              width: 10px;
              height: 10px;
              margin-right: 6px;
            }
          }
          .co2-info-wrapper {
            display: flex;
            column-gap: 5px;
            p {
              margin-top: 0px;
              margin-bottom: 0px;
              align-self: center;
            }
          }
        }
        .comparative:first-child {
          margin-right: 11px;
        }
      }
    }
    .icon-text-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

.total-scope3-card {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
}

.chart-container {
  width: 30%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.co2-information-scope3 {
  position: absolute;
  top: 45%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
