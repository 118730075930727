@import 'src/styles/colorVariables';

.onboarding {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  .onboarding-section {
    width: 100%;
    max-width: 1920px;
    max-height: 1080px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    position: relative;
    overflow-y: auto;

    &__logo {
      width: 114px;
      height: 38px;
      align-self: flex-start;
      position: absolute;
      left: 5rem;
      top: 2rem;
      @media (max-width: 768px) {
        left: 2rem;
        top: 1rem;
      }
    }
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 680px;
      width: 100%;
      margin-top: auto;
      padding-top: 4rem;
      h1 {
        text-align: center;
        margin: 0;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      h3 {
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
        max-width: 507px;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      h3.create-password {
        width: 680px !important;
      }
    }
    // Onboarding Form
    &__onboarding-form {
      max-width: 332px;
      .input {
        margin-bottom: 16px;
      }
      .divider {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 24px;
        &__line {
          height: 1px;
          width: 50%;
          background-color: $gray-color;
        }
        span {
          margin-left: 4px;
          margin-right: 4px;
        }
      }
      &__buttons {
        display: flex;
        button {
          margin-left: 18px;
        }
        button:first-child {
          margin-left: 0;
        }
      }
      &__footer {
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        span {
          cursor: pointer;
          margin-right: 3px;
        }
      }
    }
    // Register form
    &__register-form {
      max-width: 506px;

      @media (max-width: 768px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .input-element {
        .input {
          width: 100% !important;
          margin-bottom: 8px;
        }
      }
      .input--basic {
        width: 10% !important;
      }
      .newsletter-checkbox {
        margin-bottom: 12px;
      }
      &__privacy-policy-text {
        display: flex;
        align-items: center;
        span {
          margin-right: 3px;
        }
      }

      &__footer {
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        max-width: 506px;
        width: 100%;
        margin-bottom: auto;
        padding-bottom: 1rem;
        @media (max-width: 768px) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
    }

    // RegisterInvited form
    &__register-invited-form {
      width: 410px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .input {
        width: 100%;
        margin-bottom: 16px;
      }
      .newsletter-checkbox {
        margin-bottom: 12px;
      }
      &__privacy-policy-text {
        display: flex;
        align-items: center;
        span {
          margin-right: 3px;
        }
      }

      &__footer {
        justify-content: center;
        align-items: center;
        margin-top: 49px;
        width: 410px;
      }
    }

    // Onboarding User
    .onboarding-user {
      &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 16px;
        width: 641px;

        h2 {
          margin: 0;
        }
        h3 {
          margin-top: 16px;
          margin-bottom: 52px;
          width: 554px;
        }
      }
      &__form {
        width: 641px;
      }
      &__footer {
        width: 641px;
        .skip-onboarding {
          margin-left: 48px;
          cursor: pointer;
        }
      }
    }
  }
}

.email-sent {
  width: 100vw;
  display: flex;
  justify-content: center;
  .email-sent-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32px 116px;
    max-width: calc(1440px - 232px);
    width: 100%;
    &__logo {
      width: 114px;
      height: 38px;
      align-self: flex-start;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15%;
      width: 506px;
      h1 {
        text-align: center;
        margin: 0;
      }
      h3 {
        text-align: center;
        margin-top: 16px;
        margin-bottom: 48px;
        width: 426px;
        .email {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    &__footer {
      width: 332px;
    }
  }
}

.step-header {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  .step-number {
    color: $white;
    border-radius: 50%;
    padding: 3px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  h3 {
    margin: 0 12px 0 0 !important;
  }
}
