.organizations {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 5rem auto 1fr;

  .filters {
    margin-bottom: 1rem;
    .input {
      max-width: fit-content !important;
      min-width: fit-content !important;
    }
  }
  .loader-tables-wrapper {
    width: 100% !important;
  }
  &__list-wrapper {
    overflow-y: auto;
    margin-top: 8px;
  }
}

.delete-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.edit-organization {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .divider-line {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
