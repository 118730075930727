@import 'src/styles/colorVariables';

.input {
  &__textarea {
    textarea {
      border: 1px dashed $gray-color;
      width: 100%;
      height: 100%;
      resize: none;
      padding: 8px;
      box-sizing: border-box;
      background-color: $input-bg-color;
      color: $input-text-color;
      border-radius: 8px;
    }
  }
}
