.logistics-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.glec-carbon-footprint {
  padding: 1.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
