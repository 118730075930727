.tutorial-header {
  margin-bottom: 2em;
}

.tutorial-button-section {
  display: flex;
  gap: 0.5em;
  button {
    width: 35% !important;
  }
}

.step-numbers-tutorial {
  margin-left: auto;
  margin-top: auto;
}

.round-border {
  border-radius: 8px !important;
}
