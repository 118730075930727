.organization-selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vh;
  margin: auto;
  margin-top: 5rem;

  &__logo {
    position: fixed;
    top: 2rem;
    left: 7rem;
    max-width: 150px;
    max-height: 75px;
    height: auto;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      margin-bottom: 1rem;
    }

    h2 {
      width: 75%;
    }

    margin-bottom: 2rem;
  }
}
