.card-info-co2 {
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 13px;
  .co2-progress-bar {
    position: relative;
    width: 120px;
    box-sizing: border-box;

    .co2-information {
      position: absolute;
      top: 38%;
      left: 26%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;

      .co2-value {
        text-align: center;
      }
      .co2-units {
        text-align: center;
      }
    }
  }
  .countries-wrapper {
    margin-left: 15px;
    .tag-wrapper {
      background: transparent !important;
    }
  }
}
