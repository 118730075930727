@import 'src/styles/colorVariables';

section.ghg-layout {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 620px;
  height: 932px;

  .report-header {
    display: flex;
    align-items: center;
    column-gap: 4px;
    .logo {
      max-height: 40px;
    }
  }
  .report-header-social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      text-decoration: none;
      margin-left: 24px;
    }
  }
  .report-footer {
    width: 92%;
    display: flex;
    position: absolute;
    justify-content: space-between;
    bottom: 5px;
    right: 38px;
    align-items: center;
    column-gap: 7px;

    .sfc-logo-container {
      width: 65%;
      color: $white;
    }

    .tag-white {
      background-color: $white;
      padding: 4px 4px;
      border-radius: 50px;
      box-sizing: border-box;
      display: inline-block;
      border: 1px solid white;
    }
    .tag-black {
      background-color: rgba(25, 42, 62, 0.8);
      color: $white;
      padding: 4px 4px;
      border-radius: 50px;
      box-sizing: border-box;
      display: inline-block;
      border: 1px solid $white;
    }
    .tag-transparent-black {
      background-color: transparent;
      color: $white;
      padding: 4px 4px;
      border-radius: 50px;
      box-sizing: border-box;
      display: inline-block;
      border: 1px solid $white;
    }
    .tag-transparent-white {
      background-color: transparent;
      padding: 4px 4px;
      border-radius: 50px;
      box-sizing: border-box;
      display: inline-block;
      border: 1px solid rgba(25, 42, 62, 0.8);
    }
  }
}
