.image-flex-wrapper {
  display: flex;
  gap: 0.5em;
  img {
    width: 1.25em;
  }
}

.information-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.employee-form-button-container {
  display: flex;
}

.employee-form-button-container > button {
  margin: 2em 1em 1em 0;
}

.button-start {
  margin-top: 2.5rem;
  width: 50%;
}

.privacity-communication {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
}
