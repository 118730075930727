@import '../../../../../../../src/styles/fontClasses.scss';
@import '../../../../../../../src/styles/colorClasses.scss';
@import '../../../../../../../src/styles/colorVariables.scss';

.self-organization-header {
  @extend .headline4-font;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  height: 3rem;
  width: 100%;

  box-sizing: border-box;
}

.self-organization-row {
  @extend .input-font;

  display: flex;
  align-items: center;

  height: 4rem;
  width: 100%;

  box-sizing: border-box;
}

.self-organization-row.last-ones {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.self-organization-row.gray {
  @extend .gray-bg-color2;
}

.report {
  .self-organization-header {
    @extend .body3-font;
  }

  .self-organization-row {
    @extend .body3-font;
  }
}

.hyperlink-logistics {
  color: $black-color;
  text-decoration: underline !important;
}
