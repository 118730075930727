.unbind-account {
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .buttons {
    margin-top: 21px;
  }
}
