@import 'src/styles/colorVariables';

.report-card-header {
  .title {
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    .border-left {
      width: 3px;
      border-radius: 5px;
      height: 16px;
      background-color: $primary-light-color;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 13px;
    }
  }
  p {
    margin-top: 0px;
    margin-bottom: 9px;
    // width: 150px;
  }
}
