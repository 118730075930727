@import 'src/styles/colorVariables';

.divider-line {
  width: 1px;
  height: 50%;
  align-self: center;
  background-color: $gray-color;
}

.image-wrapper-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right-width: 1px;
  border-right-style: solid;
  padding: 0.5em 0.66em;

  height: 100%;
  box-sizing: border-box;
}

.input-wrapper-plain {
  border-radius: 5px;
  border: 1px solid;
  align-items: center;
  overflow: hidden;
}

.image-wrapper-input.small {
  padding: 12px;
}

.image-wrapper-input.medium {
  padding: 14px;
}

.image-wrapper-input.large {
  padding: 16px;
}

.input-wrapper-plain.small {
  height: calc(1.5rem - 2px); // Should be 1.5rem - 2px from border
}

.input-wrapper-plain.medium {
  height: calc(2rem - 2px); // Should be 2rem - 2px from border
}
.input-wrapper-plain.large {
  height: calc(2.5rem - 2px); // Should be 2.5rem - 2px from border
}

.input-element-wrapper-2 {
  width: auto;
  // min-width: 0;
  flex: 1;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  display: flex;
}

.non-input-element-wrapper {
  display: flex;
  align-items: center;
  padding: 0.5em;
}
