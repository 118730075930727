.sbti {
  &__steps-logo {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .sbti_logo {
    width: 6%;
  }

  .card-cta-wrapper {
    margin: 1em 0;

    .subtitle3-font:last-child {
      margin-top: -0.5em;
    }
  }

  .m-0-2 {
    margin-left: 0.2rem;
  }

  .step-section {
    font-size: 14px;
    padding: 1.7rem 1.5rem;

    &__step {
      margin: 1.5rem 0;
      display: flex;
      align-items: center;
    }
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}
