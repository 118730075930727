@import 'src/styles/colorVariables';
.card-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding:  1rem 0.5rem;
  gap: 0.5rem;
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1 auto;

    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;

    column-gap: 0.5rem;

    h4 {
      margin: 0;
    }
    h3{
      margin: 0;
    }
    button.blocked-button {
      margin-left: auto;
    }
    .right-filters-wrapper {
      display: flex;
      flex: 1 1 auto;
      .filter-wrapper-left {
        width: 100%;
      }
    }
  }

  &__body {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
  }

}
.card-horizontal {
  display: flex;
  gap: 0.5rem;
  border-radius: 5px;
  width: calc(25% - 0.75rem);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100px;
  min-width: 290px;
  max-width: 310px;
  max-height: 120px;
  &.card-button {
    cursor: pointer;

    &:hover {
      border: 1px solid $primary-color !important;
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1 auto;

    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;

    column-gap: 0.5rem;

    h4 {
      margin: 0;
    }
    button.blocked-button {
      margin-left: auto;
    }
    .right-filters-wrapper {
      display: flex;
      flex: 1 1 auto;
      .filter-wrapper-left {
        width: 100%;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  &__body--scope {
    grid-template-rows: auto 1.4fr;
    .compare-information-wrapper:first-child {
      margin-top: 0.75rem;
    }
    .compare-information-wrapper:last-child {
      margin-bottom: 0.5rem;
    }
  }

  &__body--evolution {
    grid-template-rows: auto 1.7fr;
  }

  &__body--map {
    display: flex;
    flex-direction: column;
  }

    .button-dropdown {
      cursor: pointer;
      z-index: 5;
      display: block;
      .button-wrapper {
        float: right;
      }
      .button-dropdown-options {
        right: 0 !important;
        left: auto;
      }
    }

    &__content-wrapper {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      height: 100%;
      img {
        cursor: auto !important;
      }
      h1 {
        text-align: center;
      }
      p {
        text-align: center;
      }
      .total-info {
        display: flex;
        align-items: center;
        p {
          margin: 0 0 0 6px;
        }
      }
    }
  }

.tooltip-card-measure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  border-radius: 5px;
}

.tooltip-card-measure p {
  margin: 0;
}

.card-list.list {
  flex-direction: column;
  .card {
    height: auto;
    width: 100%;
    .create-new-card {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      h1 {
        margin-top: 0;
      }
      .button-wrapper {
        width: 100%;
      }
    }
  }
}
