@import 'src/styles/mixins';
@import 'src/styles/mediaQueries';

.dashboard-no-data {
  .landing-card {
    height: 100%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include break($small) {
      margin: 1rem 0.2rem;
    }
    .dashboard-card {
      &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 0 0 auto;
        &.landing-image {
          display: flex;
          justify-content: center;
          align-content: center;
          img {
            width: 30%;
            max-width: 160px;
            height: auto;
          }
        }
      }
      &__body {
        max-width: 45%;
        margin: 0 auto;
        text-align: center;
        line-height: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        span:nth-of-type(1) {
          margin-top: 2.875rem;
          margin-bottom: 0;
        }
        span:nth-of-type(2) {
          margin: 2.375rem auto;
        }
        @include break($small) {
          max-width: 100%;
        }
      }
    }
  }
}
