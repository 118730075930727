.improving-cards {
  margin: 1rem;
  margin-top: 0;
  column-gap: 8px;
  // Improve

  .esgMetrics-chart {
    padding: 0.8em;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -0.5rem;

    .progress-datapoints {
      margin-left: 2rem;
    }

    .progress-datapoints-number {
      position: absolute;
    }
  }

  .improve-card {
    padding: 1rem;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
    .improve-card-first-section {
      h4 {
        margin-top: 0px;
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .improve-card-second-section {
      .percentage-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        gap: 1rem;
      }
    }
    // button {
    //   // display: inline-block;
    //   width: auto;
    //   padding: 8px;
    //   font-size: 12px;
    //   line-height: 120%;
    //   width: 40%;
    // }
  }
}
