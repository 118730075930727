.ghg-transport-distribution {
  .list-description-wrapper {
    .distribution-description-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .distribution-card {
        padding: 1rem;
        border-radius: 8px;
      }
    }
    .description-box {
      width: auto !important;
    }
  }
}
