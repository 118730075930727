.modal-supplier-webinar {
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  cursor: pointer;
  position: relative;
  background: #ffffff;
  border-color: #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-supplier-webinar .table-wrapper table tbody tr td {
  border-bottom: 0;
}
