.framework-dashboard__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  overflow-y: auto;
  overflow-x: auto;
  gap: 1rem;
}

.framework-scope__icon{
  max-height: 64px;
  width: auto;
}