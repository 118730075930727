

.avoided-emission {
    .infinite-scroll-wrapper-card {
        margin-top: 1rem;
    }
    .card-dropdown {
        width: 25%;
    }
}

.list-in-avoided-emissions {
    display: grid;
    grid-template-rows: 20rem;
    overflow-y: auto;
}
