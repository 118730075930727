.esg-metrics{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.esg-metrics__header{
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}
.infography-esg-metrics {
  width: 100%;
  height: auto;
}
.infography-esg-metrics__container{
  position: relative;
  padding-bottom: 20px;
  .infography-fakebtn{
    position: absolute;
    width: 32%;
    height: 30%;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

.infography-container {
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.table-container {
  max-height: 55vh;
}

.infinite-list-datapoints .infinite-scroll-container .table-header {
  justify-content: flex-end;
}
