.list-organizations {
    .organization {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .organization:hover {
        cursor: pointer;
    }

    .change-org {
        margin-left: auto;
    }
}