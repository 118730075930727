@import 'src/styles/colorVariables';
@import 'src/styles/fontVariables';

.reach-environment-impact-tag--company {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  height: fit-content;
}

.bar-chart-wrapper {
  font-size: large;
  font-weight: 100;
}
.dashboard-card {
  .bar-chart-scroll-wrapper {
    flex: 1 1 auto;
  }
}
