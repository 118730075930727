.group-intensity-metrics {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 65vh;
    overflow-y: auto;
  }
  .organization-intensity-metrics {
    .intensity-metric-groups {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
      .intensity-metric-group {
        display: flex;
        align-items: center;
      }
    }
  }
  .form-button-section {
    margin-top: 1rem;
  }
}
