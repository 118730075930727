.template {
  margin-right: 0.9em;
  text-transform: lowercase;
}

.download-csv-container {
  display: flex;
  align-items: center;

  span {
    margin-left: 0.3em;
  }
}
