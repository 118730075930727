.modeling-sbti {
  width: 100%;
  height: calc(100vh - 145px);
  margin-top: 1rem;
}

.modeling-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2.3fr;
  grid-template-rows: 1fr 1.35fr;
  grid-template-areas:
    'objective chart'
    'table table';
  gap: 0.5rem;
}

.infinite-scroll-wrapper-sbti {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 2em;
}
