@import 'src/styles/colorVariables';
.card-tooltip-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;

  width: 100%;
  gap: 1rem;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .card {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 5px;

    flex: 0 0 calc((100% / 3) - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 32px 12px 12px 12px;
    cursor: pointer;

    .coming-soon-tag {
      border-radius: 5px;
      padding: 7px 7px;
      box-shadow: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -11px;
      right: 18px;
      img {
        margin-right: 5px;
      }
    }
    .tooltip {
      align-self: flex-end;
      position: absolute;
      right: 18px;
      top: 18px;
    }

    &__tags {
      display: flex;
      gap: 8px;
      .tooltip {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
      }
      .text-tag {
        border-radius: 3px;
        display: inline-block;
        padding: 2px 5px;
      }
    }
    &__content-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 12px 12px 12px 12px;
        h1 {
          margin: 16px 0 8px 0;
          text-align: center;
        }
        p {
          margin: 0 0 16px 0;
          text-align: center;
        }
        .total-info {
          display: flex;
          align-items: center;
          min-height: 14px;
          p {
            margin: 0 0 0 6px;
          }
        }
      }
      .divider {
        height: 80%;
        width: 1px;
        align-self: center;
      }
    }

    .corner-position {
      position: absolute;
      top: 0.75rem;
    }
  }
  .card:hover {
    border: 1px solid $primary-color !important;
  }
}
