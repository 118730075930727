@import 'src/styles/colorVariables';
@import 'src/styles/mediaQueries';
@import 'src/styles/mixins';

.filters-wrapper-left-side {
  width: 220px;
  position: fixed;
  left: -252px;
  bottom: 25px;
  height: 73%;
  padding: 16px 16px;
  background: $white;
  z-index: 2;
  border-radius: 8px;
  box-shadow: 1px 4px 14px 0px #c4c4c480;
  transition: 0.3s;
  overflow-y: hidden;
  &.in {
    left: 0;
  }
}

.filters__section::first-child {
  padding-top: 0px !important;
}
.filters {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding-right: 16px;

  &__section {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  &__header {
    h4 {
      margin: 8px 0px;
    }
    img {
      cursor: pointer;
    }
  }
  &__header,
  &__section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  &__section-header {
    gap: 8px;
    justify-content: flex-start;
    .filters_section-h5 {
      font-size: 12px;
      font-weight: 600;
    }
  }
  &__options {
    .modern-checkbox-container {
      padding: 8px 0px;
      .checkbox-font {
        font-size: 12px !important;
        font-weight: 400 !important;
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    span {
      font-size: 12px !important;
    }
  }
  &__option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
}

.filter-content {
  .filter-buttons {
    button {
      white-space: nowrap;
    }
  }
  .filter-total {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 16px;
    height: 16px;
  }
}
