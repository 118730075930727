.grouping {
  height: 100%;
  display: grid;
  grid-template-rows: 0.1fr auto 1fr;
  margin-top: 1rem;
  .infinite-scroll-wrapper-card {
    margin-top: 1rem;
    overflow-y: auto;
  }
}
