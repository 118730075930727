@import 'src/styles/colorVariables';

.improve-tasks {
  height: 47.5%;
  width: 100%;
  margin-top: 1.5%;
  padding: 2%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .card-header {
    display: flex;
    justify-content: space-between;
    h5 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .right-section {
      display: flex;
      button {
        margin-right: 15px;
        max-width: 103px;
      }
    }
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 85%;
    img {
      height: 100%;
    }
  }
}
