.powered-by-dcycle {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  z-index: 100;
}

.powered-by-dcycle.bottom {
  position: absolute;
  bottom: 10px;
  right: 55px;
}

.powered-by-dcycle.small {
  img {
    height: 7px;
  }
}
.powered-by-dcycle.medium {
  img {
    height: 18px;
  }
}
