.input-search {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  margin-top: 4px;
  &--basic {
    margin-bottom: 0px !important;
  }
  .label-wrapper {
    display: flex;
    label {
      margin-bottom: 0px;
      margin-right: 8px;
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
  label {
    display: inline-block;
    align-self: flex-start;
    // margin-bottom: 8px;
    height: 1rem;
  }
  .small {
    .image-wrapper {
      padding: 10px !important;
      img {
        width: 12px;
        height: 12px;
      }
    }
    input,
    .input__calendar__content,
    .input__select__content {
      padding: 0px 5px !important;
      height: calc(100% - 10px);
      box-sizing: border-box;
      width: 100%;
    }
    .select-options {
      top: calc(100% - 5px) !important;
      // width: 100%;
    }
    .input--basic {
      .select-options {
        width: calc(100% + 5px) !important;
      }
    }
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    border-right-width: 1px;
    border-right-style: solid;

    height: 100%;
    box-sizing: border-box;

    img {
      width: 18px;
      height: 18px;
    }
  }
  &__search {
    border-radius: 5px;
    border: 1px solid;
    align-items: center;

    &__content {
      border: none;
      padding: 5px;
      width: 100%;
      appearance: none;
    }
  }
}
