@import 'src/styles/colorVariables';

.report-index {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
  &__item {
    display: flex;
    align-items: center;
    padding: 18px 16px;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.1);
    border: 1px solid $gray-color2;
    border-radius: 5px;
    margin-bottom: 16px;
    text-decoration: none;
    background-color: $card-bg-color;
    p {
      margin-bottom: 0;
      margin-top: 0;
      margin-left: 13px;
    }
  }
}
