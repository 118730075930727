.arrow-button-wrapper {
  margin-bottom: auto;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-chart-scroll-wrapper {
  display: flex;
  max-height: 92%;
  height: 100%;
  max-width: 100%;
}

.chart-element-wrapper {
  width: 100%;
}
