.how-to-export {
  .card-list {
    margin-bottom: 1rem;
    overflow-y: auto;
    max-height: 500px;
    .card-wrapper {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }
  
}
