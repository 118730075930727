.cards-filter {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid;
  width: max-content;
  gap: 8px;
  button {
    width: fit-content !important;
  }
  .input {
    width: fit-content;
  }
  .input:last-child {
    margin-right: 0px !important;
  }
}

.filter-wrapper .input__select__content {
  min-width: 8em;
}
