.dashboard {
  position: relative;
  &__body-logistic {
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    @media (max-width: 85rem) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, min(80vh, 30rem));
      overflow-x: auto;
    }
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, min(calc(40vh - 0.25rem), 30rem));
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
  }
}
