.logistics-help-buttons {
  display: flex;
  column-gap: 0.2rem;
  button {
    white-space: nowrap;
  }
}

.data-visualization {
  display: flex;
  align-items: center;
  display: flex;
  gap: 0.5rem;

  div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.logo-and-data-visualization {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 0.5rem;
}

.logo-and-data-visualization > .sfc-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.add-1::after {
  content: '1';
  font-weight: 500;
}
