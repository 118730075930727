@import 'src/styles/fontVariables';
.report-list {
  table {
    width: 100%;
    border-spacing: 0px;
    thead {
      th {
        font-family: $font-bold;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.03em;
        line-height: 120%;
        font-size: 10px;
        text-align: left;
        padding-bottom: 0.5rem;
      }
    }
    tbody {
      td {
        font-family: $font-regular;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.03em;
        line-height: 120%;
        font-size: 10px;
        text-align: left;
        padding: 0.5rem;
      }
    }

    .percentage {
      padding: 0.2rem;
      border-radius: 5px;
      white-space: nowrap;
      min-width: fit-content;
    }
  }
}
