.subscription-reminder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
  width: 100%;
  position: fixed;
  z-index: 101;
  .subscription-reminder-content {
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    h5 {
      margin: 0;
    }
    button {
      margin-left: 12px;
      width: auto !important;
    }
  }
  span {
    margin-left: auto;
    margin-right: auto;
  }
}
