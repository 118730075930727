@import 'src/styles/colorVariables';
.step-page {
  width: 100vw;
  display: flex;
  .step-page-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    white-space: pre-line;
    // align-items: center;
    padding: 5% 15%;
    max-width: calc(1440px - 232px);
    width: 100%;
    &__logo {
      position: fixed;
      top: 2em;
      left: 2em;
    }

    .language-selector {
      position: fixed;
      top: 2em;
      right: 2em;
      @media screen and (max-width: 768px) {
        left: 25%;
        right: 0;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 86px;
      width: 680px;

      h1 {
        text-align: center;
        margin: 0;
      }
      h3 {
        text-align: center;
        margin-top: 16px;
        margin-bottom: 48px;
        width: 507px;
      }
      h3.create-password {
        width: 680px !important;
      }
    }
    // Onboarding Form
    &__form {
      width: 332px;
      .input {
        margin-bottom: 16px;
      }
      .divider {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 24px;
        &__line {
          height: 1px;
          width: 50%;
          background-color: $gray-color;
        }
        span {
          margin-left: 4px;
          margin-right: 4px;
        }
      }
      &__buttons {
        display: flex;
        button {
          margin-left: 18px;
        }
        button:first-child {
          margin-left: 0;
        }
      }
      &__footer {
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        span {
          cursor: pointer;
          margin-right: 3px;
        }
      }
    }
    // Register form
    &__register-form {
      width: 506px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0px 8px;
      .input {
        margin-bottom: 16px;
      }
      .input-element {
        flex-basis: calc(50% - 8px);
        flex-grow: 1;
        margin-bottom: 16px;
      }
      .input-element {
        .input {
          width: 100% !important;
          margin-bottom: 8px;
        }
      }
      .input--basic {
        width: auto !important;
      }
      .newsletter-checkbox {
        margin-bottom: 12px;
      }
      &__privacy-policy-text {
        display: flex;
        align-items: center;
        span {
          margin-right: 3px;
        }
      }

      &__footer {
        justify-content: center;
        align-items: center;
        margin-top: 54px;
        width: 506px;
      }
    }

    // RegisterInvited form
    &__register-invited-form {
      width: 410px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .input {
        width: 100%;
        margin-bottom: 16px;
      }
      .newsletter-checkbox {
        margin-bottom: 12px;
      }
      &__privacy-policy-text {
        display: flex;
        align-items: center;
        span {
          margin-right: 3px;
        }
      }

      &__footer {
        justify-content: center;
        align-items: center;
        margin-top: 49px;
        width: 410px;
      }
    }

    // Login Form
    &__login-form {
      width: 332px;
      .input {
        margin-bottom: 16px;
      }
      .divider {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 24px;
        &__line {
          height: 1px;
          width: 50%;
          background-color: $gray-color;
        }
        span {
          margin-left: 4px;
          margin-right: 4px;
        }
      }
      p {
        cursor: pointer;
      }
      &__buttons {
        display: flex;
        button {
          margin-left: 18px;
        }
        button:first-child {
          margin-left: 0;
        }
      }
      &__footer {
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        span {
          cursor: pointer;
          margin-right: 3px;
        }
      }
    }

    // Onboarding User
    .step-page-user {
      &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 16px;
        margin-left: 20%;
        margin-right: auto;
        @media screen and (max-width: 768px) {
          margin-top: 75px;
        }

        h2 {
          margin: 0;
        }
        h3 {
          margin-top: 16px;
        }
      }

      &__form {
        margin-left: 20%;
        margin-right: auto;
      }
      &__footer {
        width: 641px;
        .skip-onboarding {
          margin-left: 48px;
          cursor: pointer;
        }
      }
    }

    .checkbox {
      margin-top: 1rem;
    }
  }
}

.email-sent {
  width: 100vw;
  display: flex;
  justify-content: center;
  .email-sent-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32px 116px;
    max-width: calc(1440px - 232px);
    width: 100%;
    &__logo {
      width: 114px;
      height: 38px;
      align-self: flex-start;
    }

    &__form {
      margin-left: auto;
      margin-right: auto;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15%;
      width: 506px;
      h1 {
        text-align: center;
        margin: 0;
      }
      h3 {
        text-align: center;
        margin-top: 16px;
        margin-bottom: 48px;
        width: 426px;
        .email {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    &__footer {
      width: 332px;
    }
  }
}

.step-page-form {
  &__content {
    margin-top: 20px;
    margin-left: 15px;
    border-left: 1px solid $gray-color2;
    padding-left: 33px;
    width: 300px;
    .select-card-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      .select-card {
        border: 1px solid $gray-color;
        border-radius: 8px;
        box-sizing: border-box;
        background-color: $white;
        padding: 21px 6px 18px 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 96px;
        width: calc(33% - 7px);
        margin-right: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        span {
          margin-top: 10px;
          text-align: center;
        }
      }
      .step-card--selected,
      .step-card:hover {
        border: 1px solid $primary-color;
      }
    }

    .input-list-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .input-element {
        width: calc(50% - 8px);
        .input {
          width: 100%;
        }
      }
    }
    .input-wrapper {
      width: 100%;
    }

    .checkbox-margin {
      margin-top: 10px;
      margin-bottom: 18px;
    }
    .users-invited-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }
  }
  &__footer {
    width: 100%;
    margin-top: 1.5rem;
    .buttons-invite {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        width: 100%;
      }
    }
    .start-button {
      margin-top: 49px;
    }
  }
}

.skip-onboarding {
  margin-left: 48px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .step-page-user {
    &__form {
      margin-left: auto !important;
    }
  }

  .step-page-user__header {
    margin-left: auto !important;
    margin-top: 1em;
  }
}

.step-page-user__header,
.step-page-user__form {
  max-width: 90vw;
}

.headline-employees-step {
  display: flex;
  gap: 0.5rem;
}
