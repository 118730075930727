@import 'src/styles/colorVariables';
@import 'src/styles/fontVariables';
@import 'src/styles/mixins';
@import 'src/styles/mediaQueries';

.dashboard-wrapper {
  position: relative;
  &__mode {
    width: 100%;
    @include break($small) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include break($small) {
    margin-top: 0rem;
  }
}
.dashboard {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4.5rem auto 1fr;
  height: 100%;
  &__header {
    h3 {
      margin-top: 1rem;
      margin-bottom: 0;
      @include break($small) {
        margin-top: -0.3px;
      }
    }
    h4 {
      margin-top: 0.5rem;
      margin-bottom: 0;
      @include break($small) {
        margin-top: 5px;
      }
    }

    &.page-header {
      @include break($small) {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
        height: 100%;
      }
    }
  }

  &__body,
  &__body-holdings {
    display: grid;
    gap: 1rem;
  }
}

.dashboard-card {
  display: grid;
  grid-template-rows: 2.5rem 1fr;
  gap: 0.5rem;
  height: 100%;
  border-radius: 8px;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1 auto;

    margin-left: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;

    column-gap: 0.5rem;

    h4 {
      margin: 0;
    }
    button.blocked-button {
      margin-left: auto;
    }
    .right-filters-wrapper {
      display: flex;
      flex: 1 1 auto;
      .filter-wrapper-left {
        width: 100%;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding: 0 1rem 1rem 1rem;
  }

  &__body--scope {
    grid-template-rows: auto 1.4fr;
    .compare-information-wrapper:first-child {
      margin-top: 0.75rem;
    }
    .compare-information-wrapper:last-child {
      margin-bottom: 0.5rem;
    }
  }

  &__body--evolution {
    grid-template-rows: auto 1.7fr;
  }

  &__body--map {
    display: flex;
    flex-direction: column;
  }
}

.modal-wrapper {
  .dashboard-card {
    &__body--map {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }
    &__body {
      display: grid;
      grid-template-columns: 1fr;
      margin: 1rem 1rem;
    }
  }
}
