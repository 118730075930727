.button-section {
    margin-top: 1rem;
}

.step-selector-avoided-emissions{
    margin: 0;
}

.step-carousel-wrapper {
    margin: 0.5rem 0;
}