@import 'src/styles/colorVariables';

.input-photo {
  cursor: pointer;
  position: relative;
  width: fit-content;
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: $white;
    position: absolute;
    padding-bottom: 1px;
    font-size: 13px;
    bottom: 0;
    right: 0;
  }
  img {
    max-width: 150px;
    max-height: 75px;
  }
}
