@import 'src/styles/colorVariables';

.card-dropdown,
.card-dropdown-list {
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px 16px 24px 16px;
  cursor: pointer;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
  }

  .button-wrapper {
    position: absolute;
    right: 8px;
  }

  .text-tag {
    padding: 0.3rem;
    border-radius: 8px;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
  }

  .button-dropdown {
    cursor: pointer;
    z-index: 1;
    display: block;
    height: auto;
    .button-wrapper {
      float: right;
    }
    .button-dropdown-options {
      right: 0 !important;
      left: auto;
    }
  }
  &__content-wrapper {
    .total-info {
      display: flex;
      align-items: center;
      p {
        margin: 0 0 0 6px;
      }
    }
  }
}

.card-dropdown:hover,
.card-dropdown-list:hover {
  border: 1px solid $primary-color !important;
}

.card-dropdown {
  flex-direction: column;
  width: calc(16.6% - 16px);
  @media (max-width: 1000px) {
    width: 100%;
  }
  &__content-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    row-gap: 1rem;
    height: 100%;
    img {
      cursor: auto !important;
    }
    h1 {
      margin: 0;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      margin: 0;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.card-dropdown-list {
  display: flex;
  flex-direction: row-reverse;
  height: auto;
  padding: 14px;
  width: 100%;
  .button-dropdown {
    width: fit-content;
    margin-left: 1rem;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    column-gap: 1rem;
    h1 {
      margin: 0;
      max-width: 15rem;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    p {
      margin: 0;
      max-width: 15rem;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .total-info {
      margin-left: auto;
      p {
        max-width: fit-content;
        width: auto;
      }
    }
  }
}
