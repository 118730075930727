@import 'src/styles/fontVariables';
.ghg-report-list {
  width: 100%;
  flex-grow: 1;
  table {
    width: 100%;
    border-spacing: 0px;
    table-layout: fixed;
    thead {
      display: table-header-group;
      th,
      td {
        font-family: $font-bold;
        font-style: normal;
        letter-spacing: -0.03em;
        line-height: 120%;
        font-size: 10px;
        text-align: left;
        padding: 0.75rem 1rem;
        word-wrap: break-word;
      }
    }

    tbody {
      tr {
        td {
          padding: 0.75rem 1rem;
          font-family: $font-regular;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.03em;
          line-height: 120%;
          text-align: left;
          font-size: 10px;
        }
      }
      tr:last-child td {
        border: none;
      }
    }

    tr td:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    tr td:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    tr th:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    tr th:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .icon-text-wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    .icon-wrapper {
      width: 20px !important;
      height: 20px !important;
      padding: 5px !important;
      border-radius: 3.75px !important;
      box-sizing: border-box;
      img {
        width: 10px;
        height: 10px;
      }
    }
  }
}
