@import 'src/styles/colorVariables';
.has-label {
  margin-top: 1rem;
}
.input-multiple {
  .multiple {
    display: flex;
    align-items: center;
  }
  .input-element-wrapper {
    display: flex;
    position: relative;
    width: 50%;
    height: 100%;
    align-items: center;
    padding: 0 0.25rem;
    .input > div {
      border: none;
    }
    .input-label-wrapper {
      position: absolute;
      top: -1.5rem;
      left: 0;
      span {
        white-space: nowrap;
      }
    }
  }

  .divider-line {
    width: 1px;
    background-color: $gray-color;
    height: 20px;
    position: relative;
  }
}
