@import 'src/styles/colorVariables';

.dcycle-report {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(180deg, #000000 0%, rgba(25, 42, 62, 0.8) 100%);
  .name-logo {
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
      margin-top: 0 !important;
      width: 15px;
      height: 15px;
      max-height: 10px;
    }
  }
  .buttons {
    display: flex;
    column-gap: 1rem;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: 16px;
    button {
      border: none !important;
    }
  }

  .percentage {
    white-space: nowrap;
    min-width: fit-content;
  }
  section {
    padding: 34px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 844px;
    height: 532px;

    .card {
      display: flex;
      flex-direction: column;
    }

    .report-header {
      display: flex;
      align-items: center;
      span {
        margin-left: 4px;
      }
    }
    .report-header-social {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        text-decoration: none;
        margin-left: 24px;
      }
    }
    .report-footer {
      display: flex;
      position: absolute;
      bottom: 4px;
      right: 38px;
      align-items: center;
      column-gap: 7px;

      .tag-white {
        background-color: $white;
        padding: 4px 4px;
        border-radius: 50px;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid white;
        color: $on-light-text-color;
      }
      .tag-black {
        background-color: rgba(25, 42, 62, 0.8);
        color: $white;
        padding: 4px 4px;
        border-radius: 50px;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid $white;
      }
      .tag-transparent-black {
        background-color: transparent;
        color: $white;
        padding: 4px 4px;
        border-radius: 50px;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid $white;
      }
      .tag-transparent-white {
        background-color: transparent;
        padding: 4px 4px;
        border-radius: 50px;
        box-sizing: border-box;
        display: inline-block;
        border: 1px solid rgba(25, 42, 62, 0.8);
      }
    }

    .report-footer-logo {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 32px;
      right: 38px;
      column-gap: 0.5rem;
      img {
        height: 18px;
      }
    }

    .measure-detail-report--columns {
      flex-direction: column;
      .card {
        width: 100%;
      }
    }
    .measure-detail-report {
      display: flex;
      height: calc(100% - 32px);
      margin-top: 12px;
      column-gap: 20px;
      .card {
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        flex-basis: 50%;
        flex-grow: 2;
        padding: 24px 19px;
        display: flex;
        flex-direction: column;

        .cards-wrapper {
          display: flex;
          height: 100%;
          // margin-top: 12px;
          .distribution-element {
            width: 100%;
            margin-right: 10px;
            .icon-title {
              display: flex;
              align-items: center;
              margin-bottom: 6px;
              img {
                width: 8px;
                height: 8px;
                margin-right: 6px;
              }
            }
            .co2-info {
              display: flex;
              justify-content: flex-end;
              margin-top: 6px;
              span {
                margin-right: 5px;
              }
            }
          }
          .distribution-element:last-child {
            margin-right: 0 !important;
          }
        }
        .card-info-co2-full {
          border-radius: 8px;
          padding: 9px 11px;
          display: flex;
          flex-direction: column;
          align-items: center;

          height: calc(100% - 64px);
          box-sizing: border-box;
          .co2 {
            margin-right: 8px;
          }
          img {
            margin-left: auto;
          }

          .co2-progress-bar {
            position: relative;
            width: 100px;
            box-sizing: border-box;
            align-self: center;
            margin-bottom: 24px;

            .co2-information {
              position: absolute;
              top: 35%;
              left: 22%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 60%;

              .co2-value {
                text-align: center;
              }
              .co2-units {
                text-align: center;
              }
            }
          }
          .legend-distribution {
            display: flex;
            flex-wrap: wrap;
            width: 75%;
            gap: 8px;
            // justify-content: space-between;
            .legend-item-wrapper {
              flex: 1 1 auto;
              .legend-item {
                display: flex;
                align-items: center;
                white-space: nowrap;
                .legend-color {
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  margin-right: 4px;
                }
                .legend-percentage {
                  margin-left: 5px;
                }
              }
              .legend-item-subcategories {
                margin-top: 3px;
                .legend-item-subcategory {
                  margin-right: 2px;
                }
              }
            }
          }
        }
        .card-info-columns {
          margin-top: 6px;
          margin-bottom: 8px;
          display: flex;
          span {
            width: 26%;
          }

          span:last-child {
            margin-left: auto;
            width: 15%;
          }
          span:first-child {
            margin-left: 0;
            width: 56%;
          }
        }
        .card-footer {
          margin-top: auto;
          margin-bottom: 9px;
        }
        .rows-wrapper {
          padding: 12px;
          border-radius: 8px;
          box-sizing: border-box;
          .card-info-rows {
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name-logo {
              display: flex;
              align-items: center;

              width: 45%;
              img {
                margin-right: 8px;
                margin-top: 0 !important;
                width: 15px;
                height: 15px;
                max-height: 10px;
              }
            }

            .stacked-bar {
              max-width: 150px;
              min-width: 150px;
            }

            span:last-child {
              margin-left: auto;
              width: 15%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2px 2px;
              border-radius: 12px;
              margin-left: 5%;
              box-sizing: border-box;
            }
            .legend {
              display: flex;
              align-items: center;
              margin-right: 8px;
              .dot,
              img {
                width: 6px;
                height: 6px;
                margin-right: 6px;
              }
            }
          }
          .card-info-rows:last-child {
            margin-bottom: 0 !important;
          }
        }
        .vertical-bars {
          padding: 12px;
          border-radius: 8px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          .vertical-bar-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 8px;
            .icon-text-wrapper {
              display: flex;
              align-items: center;
              margin-top: 10px;
              img {
                min-width: 7px;
                max-width: 16px;
                min-height: 7px;
                max-height: 10px;
                height: auto;
                margin-right: 4px;
              }
              span {
                margin-top: 0 !important;
              }
            }
            span:first-child {
              margin-bottom: 10px;
            }
            span:last-child {
              margin-top: 10px;
            }
          }
          .vertical-bar-wrapper:last-child {
            margin-right: 0px !important;
          }
        }
        .total-info-wrapper {
          margin-top: auto;
        }
        .total-info-cards {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 7px;
          .total-info {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 50%;
          }
        }
      }
    }
  }
}

.legends {
  padding: 12px 12px 4px 12px !important;
  .card-info-rows {
    flex-wrap: wrap;
    .legend {
      margin-bottom: 8px;
    }
  }
}

.scope-tag {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.tag {
  padding: 0.2em 0.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 1.5vh;
}

.tag-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5vh;
  padding: 0.2em 0.5em;
  border-radius: 5px;
}
