@import 'colorVariables';
@import 'shadow';


/*
#################### TEXT COLORS ####################
*/

.on-dark-text-color {
  color: $on-dark-text-color;
}

.on-light-text-color {
  color: $on-light-text-color;
}
.on-light-text-color2 {
  color: $on-light-text-color2;
}

.secondary-text-color {
  color: $secondary-text-color;
}

.highlight-text-color {
  // color: linear-gradient($primary-color, $primary-light-color);
  background: -webkit-linear-gradient($primary-color, $primary-light-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight-report-text-color {
  color: $primary-light-color;
}

.error-text-color {
  color: $error-color;
}
.strong-error-text-color {
  color: $strong-error-color;
}
.error-background {
  color: $error-background;
}

.disabled-text-color {
  color: $disabled-color;
}

.input-placeholder-color {
  color: $placeholder-text-color;
}

.on-tag-text-color {
  color: $on-tag-text-color;
}
.on-icon-text-color {
  color: $on-icon-text-color;
}

.notification-text-color {
  color: $notification-text-color;
}

.text-white {
  color: white;
}

.text-gradient {
  background: -webkit-linear-gradient($primary-color, $primary-light-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*
#################### BORDER COLORS ####################
*/

.input-border-color {
  border-color: $gray-color !important;
}

.input-border {
  border: 1px solid $gray-color;
}

.card-border-color {
  border-color: $gray-color2 !important;
}

.error-border-color {
  border-color: $error-color !important;
}

.solid-border {
  border: 1px solid $gray-color2;
}
.dashed-border {
  border: 1px dashed $gray-color2;
}
.highlight-border-color {
  border: 1px solid $highlight-border-color !important;
}

.notification-border {
  border: 0;
}

.border-gray-2-light {
  border-color: $gray-light-2-color;
}

.border-primary {
  border-color: $primary-color;
}

.border-neutral-gray-warm-60 {
  border-color: $neutral-gray-warm-60;
}

.border-neutral-gray-warm-40 {
  border-color: $neutral-gray-warm-40;
}

.border-r-neutral-gray-warm-60 {
  border-right-color: $neutral-gray-warm-60;
}
/*
#################### BACKGROUND COLORS ####################
*/

.on-card-gray-bg-color {
  background-color: $gray-dashboard;
}

.gray-bg-color {
  background-color: $gray-color;
}

.gray-bg-color2 {
  background-color: $gray-color2;
}

.modal-bg-color {
  background-color: $modal-bg-color;
}

.main-bg-color {
  background-color: $main-bg-color;
}

.app-bg-color {
  background-color: $app-bg-color;
}

.notification-bg-color {
  background-color: $notification-bg-color;
}

.primary-bg-color {
  background-color: $primary-color;
}

.primary-light-bg-color {
  background-color: $primary-light-color;
}

.secondary-bg-color {
  background-color: $secondary-color;
}

.secondary-light-bg-color {
  background-color: $secondary-light-color;
}

.tag-bg-color {
  background-color: $tag-bg-color;
}

.review-image-bg-color {
  background-color: $review-image-bg-color;
}

.icon-bg-color {
  background-color: $icon-bg-color;
}

.menu-bg-color {
  background: transparent;
}
.transparent-bg-color {
  background: transparent !important;
}

.highlight-bg-color {
  background: linear-gradient(180deg, $primary-color, $primary-light-color);
}
.highlight-horizontal-bg-color {
  background: linear-gradient(to right, $primary-color, $primary-light-color);
}
.highlight-bg-color50 {
  background: linear-gradient(180deg, $primary-color 0%, $primary-light-color 100%);
  opacity: 0.5;
}
.loader-login-bg-color {
  background: linear-gradient(180deg, $primary-color 0%, $primary-light-color 100%);
}
.scope1-bg-color {
  background: $scope1-color;
}
.scope2-bg-color {
  background: $scope2-color;
}
.scope3-bg-color {
  background: $scope3-color;
}
.error-bg-color {
  background-color: $error-color;
}

.bar-disabled-bg-color1 {
  background-color: rgba(25, 42, 62, 0.3);
}
.bar-disabled-bg-color2 {
  background-color: rgba(25, 42, 62, 0.2);
}
.bar-disabled-bg-color3 {
  background-color: rgba(25, 42, 62, 0.1);
}

.input-disabled-bg-color {
  background-color: rgba(196, 196, 196, 0.1) !important;
  border-color: $gray-color2 !important;
}

.card-bg-color {
  background-color: $card-bg-color;
}

.card-disabled-bg-color {
  background: #f3f3f6 !important;
}

.input-bg-color {
  background-color: $input-bg-color;
}

.bg-gray-light-2 {
  background-color: $gray-light-2-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-neutral-gray-warm-80 {
  background-color: $neutral-gray-warm-80;
}

.bg-neutral-gray-80 {
  background-color: $neutral-gray-80;
}

.bg-neutral-gray-warm-40 {
  background-color: $neutral-gray-warm-40;
}

.bg-neutral-white {
  background-color: $neutral-white;
}

.bg-purple-light {
  background-color: $purple-light-color;
}

.bg-blue-tag {
  background-color: $tag-bg-color;
}

.bg-secondary-purple-20 {
  background-color: $secondary-purple-20;
}

.bg-tertiary-salmon {
  background-color: $tertiary-salmon;
}

.bg-ui-colors-green {
  background-color: $ui-colors-green;
}

.bg-white {
  background-color: white;
}

.bg-tertiary {
  background-color: $tertiary-color;
}

.bg-gray-2 {
  background-color: $gray-color2;
}

.bg-neutral-gray-warm-60 {
  background-color: $neutral-gray-warm-60;
}

.bg-secondary-purple-80 {
  background-color: $secondary-purple-80;
}

.bg-transport {
  background-color: $transport-color;
}

// HOVER BORDER CLASSES
// HOVER

.hover\:border-primary:hover {
  border-color: $primary-color !important;
}

.hover\:border-secondary-purple-20:hover {
  border-color: $secondary-purple-20 !important;
}

/*
#################### BOX SHADOWS ####################
*/

.modal-box-shadow {
  box-shadow: 0px 2px 20px rgba(25, 42, 62, 0.2);
}

.shadow-20 {
  box-shadow: $shadow-20;
}

.shadow-10 {
  box-shadow: $shadow-10;
}

.shadow-50-purple {
  box-shadow: $shadow-50-purple;
}

.hover\:shadow-20:hover {
  box-shadow: $shadow-20;
}

.hover\:shadow-50:hover {
  box-shadow: $shadow-50;
}
/*
#################### OTHERS ####################
*/

.tag-bg-text-color {
  background-color: $tag-bg-color;
  color: $on-tag-text-color;
}

.tag-selected-bg-text-color {
  background-color: $tag-selected-bg-color;
  color: $on-tag-selected-text-color;
}

.tag2-bg-text-color {
  background-color: $tag2-bg-color;
  color: $on-tag2-text-color;
}

.secondary-text-color {
  color: $secondary-color;
}

.text-primary {
  color: $primary-color;
}

.text-gray-light {
  color: $gray-light-color;
}

.text-gray-2 {
  color: $gray-2-color;
}

.text-neutral-black {
  color: $neutral-black;
}

.text-neutral-gray-20 {
  color: $neutral-gray-20;
}

.text-purple {
  color: $purple-color;
}

.text-blue-tag {
  color: $on-tag-text-color;
}

.text-neutral-gray-warm-20 {
  color: $neutral-gray-warm-20;
}

.text-neutral-gray-cold-20 {
  color: $neutral-gray-cold-20;
}

.text-secondary-purple-20 {
  color: $secondary-purple-20;
}


/* ###### STROKE FILL ###### */

.stroke-secondary-purple-20 {
  stroke: $secondary-purple-20;
}

.stroke-secondary-purple-20\!important {
  stroke: $secondary-purple-20 !important;
}


/* ###### FILL ###### */
.fill-secondary-purple-20 {
  fill: $secondary-purple-20;
}