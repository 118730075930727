@import 'src/styles/colorVariables';

.measure-main-report {
  display: flex;
  margin-top: 32px;
  margin-bottom: 43px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: -40px;
  margin-right: -40px;
  height: 300px;
  .card {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
    width: 33.33%;
    padding: 29px 25px 25px 26px;

    h1 {
      margin-top: 20px;
      margin-bottom: 11px;
    }

    p {
      margin-top: 0px;
      margin-bottom: 14px;
    }

    .icon-wrapper {
      width: 34px !important;
      height: 34px !important;
      padding: 10px !important;
    }

    .card-info {
      border-radius: 8px;
      padding: 9px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 150px;
      box-sizing: border-box;
      span {
        display: flex;
        align-items: center;
        .dot {
          border-radius: 50%;
          width: 6px;
          height: 6px;
          margin-right: 7px;
        }
      }
      span:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .card:first-child {
    margin-left: 0px !important;
  }
  .card:last-child {
    margin-right: 0px !important;
  }
}

.measure-report {
  display: flex;
  .content {
    margin-right: 74px;
    .title {
      margin-top: 65px;
      margin-bottom: 23px;
      display: flex;
      align-items: center;
      .border-left {
        width: 4px;
        border-radius: 5px;
        height: 32px;
        background-color: $primary-light-color;
      }
      h1 {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 13px;
      }
    }
    .description {
      width: 252px;
      opacity: 0.8;
    }
  }
  img {
    width: 50%;
  }
}
