@import 'src/styles/fontClasses';

.register-company {
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  width: 100%;
  box-sizing: border-box;
  .main-logo {
    width: 114px;
    height: 38px;
    align-self: flex-start;
    @media (max-width: 768px) {
      left: 0;
      top: 1rem;
      height: 24px;
    }
  }
  &__step-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;

    &__left {
      flex-basis: 50%;
      .step-carousel {
        span {
          @extend .font-body-b2-r;
        }
      }
      &__content {
        margin-top: 3rem;
      }
    }
    &__right {
      flex-basis: 50%;
    }
  }
}
