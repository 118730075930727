.datadis {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 5rem auto 1fr;

  .infinite-scroll-wrapper-card {
    overflow-y: auto;
    margin-top: 2rem;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
