@import '../../../../src/styles/colorVariables';
@import '../../../../src/styles/fontVariables';

.filter-content {
  display: flex;
  gap: 0.5rem;
  .filter-buttons {
    display: flex;
    gap: 0.5rem;
    // width: 15rem;
  }

  .filters-carousel {
    display: flex;
    // flex-wrap: wrap;
    gap: 0.5rem;
    overflow-y: auto;
    align-items: center;
    width: 100%;
    // max-width: 400px;
  }

  .filters-carousel::-webkit-scrollbar {
    display: none;
  }

  .filters-wrapper {
    display: flex;
    align-items: center;
  }

  .button-arrow-wrapper {
    display: flex;
    align-items: center;
  }

  .add-filter-body {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin-left: 0.5rem;
  }

  .counter-filter-selected {
    background: linear-gradient(to right, $primary-button-bg-color, $primary-button2-bg-color);
    border: 1px solid $primary-button-border-color;
    color: $primary-button-text-color;
    padding: 0.1rem 0.15rem;
    border-radius: 3px;
  }

  .counter-filter-selected:hover {
    background: linear-gradient(
      to right,
      $primary-button-hover-bg-color,
      $primary-button-hover2-bg-color
    );
  }
}
