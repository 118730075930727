@import 'src/styles/colorVariables';

.list-organizations {
  border: 1px solid $gray-color;
  border-radius: 5px;
  padding: 2rem 3rem;
  padding-right: 1rem;
  width: 17.5rem;

  .table {
    max-height: 17.5rem;
    overflow: auto;
  }

  .scroll {
    margin-right: 1.5rem;
  }
}
