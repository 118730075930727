@import 'src/styles/fontVariables.scss';
@import 'src/styles/colorVariables.scss';
.textareav2 {
  font-family: $font-regular;
  font-size: 12px;
  color: $placeholder-text-color;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 120%;
}
.textareav2::placeholder {
  font-family: $font-regular !important;
  font-size: 12px;
  color: $placeholder-text-color;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 120%;
}

.textareav2 {
  border: 1px dashed $gray-color;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 8px;
  box-sizing: border-box;
  background-color: $input-bg-color;
  color: $input-text-color;
  border-radius: 8px;
}
