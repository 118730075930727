@import 'src/styles/colorVariables.scss';
.loading-skeleton-wrapper {
  width: 100%;
  flex-grow: 1;
  flex-basis: calc(50% - 8px);

  span:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px 8px;
    // margin-bottom: 2rem;
    margin-top: 2rem;
  }
  span.loading-skeleton,
  span.react-loading-skeleton {
    flex-grow: 1 !important;
    flex-basis: calc(50% - 8px) !important;
    box-sizing: border-box !important;
    height: 1.5rem;
    border-radius: 8px !important;
    margin: 0 !important;
    // background-color: $gray-color;
  }
  br {
    display: none;
  }
  span.loading-skeleton::after {
    // background-image: linear-gradient(90deg, $gray-color, $app-bg-color, $gray-color);
  }
}
