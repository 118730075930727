@import 'src/styles/colorVariables.scss';
.milestones {
  padding: 42px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 16px;
    margin-top: 0;
  }
  h2 {
    margin: 0;
  }
  .milestones-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    .milestone-card {
      padding: 24px 16px;
      border-radius: 8px;
      border: 1px solid;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      height: 300px;
      h3 {
        margin-top: 0;
        margin-bottom: 24px;
      }
      .main-image {
        width: 100%;
      }
      .tooltip-info {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          width: 10px;
          height: 10px;
        }
      }
      &__steps {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .step {
          display: flex;
          gap: 8px;
          align-items: center;
          img {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .milestone-card:hover {
      border: 1px solid $highlight-border-color !important;
    }
  }
  .buttons-wrapper {
    display: flex;
    gap: 16px;
    justify-content: center;
    button {
      width: fit-content !important;
      min-width: 150px;
    }
  }
}
