@import 'src/styles';

.action-btn {
  border-radius: 8px;
  border: solid 1px $neutral-gray-warm-40;
  box-sizing: border-box;
  padding: 7px;
  margin: 0px;

  &:not([aria-disabled='true']) {
    cursor: pointer;

    &:hover {
      border: solid 1px $secondary-purple-20;
    }

    &:active {
      border: solid 1px $secondary-purple-20;
    }
  }
}
