@import 'src/styles/fontClasses';

.checks {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    span {
      @extend .font-body-b1-r;
    }
  }
}
