@import 'src/styles/colorVariables.scss';
.register-form {
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .input {
    background-color: $app-bg-color !important;
    * {
      background-color: $app-bg-color !important;
    }
  }

  .form-wrapper {
    .input {
      flex-basis: 100%;
    }

    .input__phone__content {
      flex-basis: 60%;
    }

    .newsletter-checkbox {
    }
    .privacy-policy-text {
      display: flex;
      align-items: center;
      span {
        margin-right: 3px;
      }
    }
  }

  &__footer {
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    margin-bottom: auto;
    padding-bottom: 1rem;
    @media (max-width: 768px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}
