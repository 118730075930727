.reset {
  all: unset;
}

a {
  all: unset;
}

button {
  background: unset;
  border: unset;
  padding: unset;
  text-align: unset;
  cursor: pointer;
}
