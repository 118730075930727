.input-label-wrapper {
  .label-wrapper {
    // position: relative;

    // .second-label {
    //   position: absolute;
    //   left: calc(55% + 0.5rem);
    //   display: flex;
    //   gap: 0.25rem;
    // }

    // .third-label {
    //   position: absolute;
    //   left: calc(55% + 0.5rem);
    //   display: flex;
    //   gap: 0.25rem;
    // }
    display: flex;
    // column-gap: 20%;
  }
}
