.ghg-front-page {
  .title {
    margin-top: 50px;
    h1 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
  .content {
    // width: 212px;
    margin-top: 15px;
    p {
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0.8;
    }
  }
}
