.pricing {
  margin-left: 5%;
  margin-right: 5%;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 16px;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 5%;
    margin-top: 5%;

    &__content {
      h1 {
        margin-top: 0;
        margin-bottom: 2%;
      }
      p {
        margin-top: 0;
        margin-bottom: 4%;
      }
      &__characteristics {
        margin-top: 4%;
        display: flex;
        flex-direction: column;
        row-gap: 1.5em;
        .characteristic {
          display: flex;
          align-items: center;
          column-gap: 1.2em;
        }
      }
    }
    .card {
      width: 45%;
      padding: 5% 0%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .card-form {
        display: flex;
        flex-direction: column;
        row-gap: 0.5em;
      }
      .price-container {
        margin-top: 1em;
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;
        column-gap: 0.5em;
        .tag {
          padding: 3px 8px;
          border-radius: 3px;
        }
      }
      button {
        width: 54% !important;
        margin-top: 2em;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    column-gap: 5%;
    margin-top: 5%;
  }
}
