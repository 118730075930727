@import 'src/styles/colorVariables';
@import 'src/styles/mixins';
@import 'src/styles/mediaQueries';

.tooltip-modal {
  padding: 8px;
  min-width: 171px;
  max-width: 250px;
  text-align: left;
  border-radius: 5px;
  color: $white;
  box-sizing: border-box;
}

.tooltip-arrow {
  fill: $tag2-bg-color;
}

.tooltip {
  // display: inline;
  cursor: unset;
  position: relative;
  width: fit-content;
  height: fit-content;
  max-height: fit-content;

  .icon-tooltip {
    width: 100%;
    width: 12px;
    height: 12px;
  }

  .tooltip-wrapper {
    position: fixed;
    left: 100%;
    display: flex;
    align-items: center;
    z-index: 101;

    .triangle {
      width: auto !important;
      height: auto !important;
    }
    .triangle.top {
      margin-top: -1px;
    }
    .triangle.bottom {
      transform: rotate(180deg);
      margin-bottom: -1px;
    }
    .triangle.left {
      transform: rotate(-90deg);
      margin-left: -10px;
    }
    .triangle.right {
      transform: rotate(90deg);
      margin-right: -10px;
    }
  }

  .tooltip-wrapper.right {
    transform: translate(10%, -50%);
    flex-direction: row;
  }

  .tooltip-wrapper.left {
    transform: translate(-102%, -50%);
    flex-direction: row-reverse;
  }

  .tooltip-wrapper.top {
    transform: translate(-50%, -105%);
    flex-direction: column-reverse;
  }
  .tooltip-wrapper.bottom {
    transform: translate(-50%, 5%);
    flex-direction: column;
  }

  .tooltip-wrapper.small {
    .tooltip-modal {
      min-width: 100px;
      width: fit-content;
      padding: 0.5rem;
      text-align: left;
    }
  }
  .tooltip-wrapper.large {
    .tooltip-modal {
      min-width: 200px !important;
      width: fit-content;
      padding: 0.5rem;
      text-align: left;
    }
  }

  @include break($small) {
    width: 100%;
  }
}

// tooltip chart

.tooltip-chart,
.tooltip-card {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  .tooltip-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
  }

  .tooltip-modal {
    border-radius: 5px;
    padding: 4px 8px;
    color: $white;
    z-index: 101;
    display: inline-block;
    box-sizing: border-box;
    white-space: nowrap;
  }

  .tooltip-triangle {
    width: 20px;
    position: relative;
    display: flex;
    align-items: flex-start;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .tooltip-wrapper.top {
    flex-direction: column-reverse !important;
  }
}

// tooltip map

.tooltip-map {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .tooltip-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
  }

  .tooltip-modal {
    border-radius: 11px;
    color: $white;
    z-index: 101;
    display: inline-flex;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 11px;
    .tooltip-map-content {
      margin-left: 11px;
      h1 {
        margin-top: 0;
        margin-bottom: 5px;
      }
      .tooltip-quantity {
        margin-right: 5px;
      }
    }
  }

  .tooltip-triangle {
    width: 20px;
    position: relative;
    display: flex;
    align-items: flex-start;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .tooltip-wrapper.top {
    flex-direction: column-reverse !important;
  }
}

.tooltip-wrapper {
  cursor: pointer;
  width: auto !important;
  button {
    z-index: 101;
  }
}
