@import 'src/styles/colorVariables';

.side-modal {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding-right: 16px;

  &__wrapper {
    width: 280px;
    position: fixed;
    left: -280px;
    bottom: 25px;

    padding: 16px 16px;
    background: $white;
    z-index: 2;
    border-radius: 8px;
    box-shadow: 1px 4px 14px 0px #c4c4c480;
    transition: 0.3s;
    overflow-y: hidden;

    &.in {
      left: 0;
    }

    &__full-height {
      height: 100%;
      bottom: 0;
      box-sizing: border-box;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    h4 {
      margin: 8px 0px;
    }
    img {
      cursor: pointer;
    }
  }
}
