.notification {
  z-index: 100000;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 60px;
  border-radius: 5px;
  padding: 8px 16px;
  left: 50%;
  transform: translate(-50%, 0);
  img {
    margin-left: 4px;
    cursor: pointer;
  }
}
