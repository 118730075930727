.organization-detail {
  &__buttons {
    width: fit-content;
    margin-left: auto;
    min-width: 100px;
    display: flex;
    align-items: center;
    gap: 1rem;
    button {
      white-space: nowrap;
    }
  }
  &__table-wrapper {
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 8px;
    table {
      tbody {
        tr {
          td:first-child {
            padding-right: 26px !important;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
          td:last-child {
            margin-right: 0 !important;
            .button-dropdown {
              justify-content: flex-end !important;
              .button-dropdown-options {
                right: 0 !important;
              }
            }
            .company-edit {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              img {
                height: 15px;
                width: 15px;
              }
              .img-margin-right {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .table-filters {
        display: flex;
        .input {
          margin-right: 9px;
          min-width: 120px;
          flex: 0 0 0;
        }
        .calendar-range {
          width: 210px;
          min-width: 210px;
        }
        .multi-select {
          .input__select {
            min-width: 120px;
          }
        }
      }
      .total-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}
