.childs-info-report {
  display: flex;
  height: 100%;
  margin-top: 12px;
  column-gap: 20px;
  .card {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    width: 100%;
    padding: 24px 19px;
    height: fit-content;
    h2 {
      margin-top: 0;
      margin-bottom: 6px;
    }

    .childs-list-wrapper {
      margin-top: 1rem;
      .text-center {
        display: flex;
        width: 100%;
        justify-content: end;
        align-items: center;
      }

      .environmental-debt {
        padding: 0.5em;
        border-radius: 3px;
        font-size: 9px;
      }

      .company {
        display: flex;
        align-items: center;

        img {
          width: 1em;
          margin-right: 1em;
        }
        span {
          width: 180px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
