@import 'src/styles/colorVariables.scss';
.warning-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 2px;
  border-radius: 12px;
  background: $warning-bg-color;
  color: $warning-color;
}
