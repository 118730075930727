.card-download {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 9px;
  width: 170px;

  &__share,
  &__download {
    position: absolute;
    top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0px 11.4367px 28.5918px rgba(220, 224, 249, 0.5);
    img {
      width: 16px;
      height: 16px;
    }
  }
  &__share {
    right: 2rem;
  }
  &__download {
    right: 0.5rem;
  }
  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    img {
      width: 80px;
    }
  }
  &__content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    h1 {
      margin-top: 0;
      margin-bottom: 0.1rem;
      word-break: break-word;
    }
    &__info {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      img {
        width: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}
