@import 'src/styles/colorVariables';
.card-wrapper {
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  cursor: pointer;
  width: calc(16.6% - 16px); // PENDING REFACTOR
  position: relative;
  background: $white;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 12.5rem;
  max-height: 200px;

  &--full {
    width: 100%;
  }

  &__options {
    top: 16px;
    right: 16px;
    position: absolute;
  }

  &__content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }

  &__total {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
.card-wrapper:hover {
  &:not(.card-wrapper__disabled) {
    border: 1px solid $primary-color !important;
  }
}

.card-wrapper--list {
  width: 100%;
  height: auto;
  min-height: 0;
  .card-wrapper__content {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
  }
}

@media (max-width: 1000px) {
  .card-wrapper {
    width: 100%;
    height: auto;
    min-height: 0;
    .card-wrapper__content {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0;
    }
  }
}
