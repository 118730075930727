.invoicing {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 19px;
    .card-invoice {
      border-width: 1px;
      border-style: solid;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 9px 16px;
      width: calc(13% - 16px);
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;

      @media (max-width: 1000px) {
        width: 100%;
      }

      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 32px);
        height: calc(50% + 9px);
        margin-top: -9px;
        position: relative;
        box-sizing: border-box;
        .img-preview {
          padding-top: 9px;
          padding-bottom: 9px;
        }
        .download-icon {
          height: 10px;
          width: 8px;
          cursor: pointer;
          position: absolute;
          top: 10px;
          right: 8px;
        }
      }
      h1 {
        width: 100%;
        margin-top: 3px;
        margin-bottom: 7px;
      }
      &__list {
        width: 100%;
        &__item {
          display: flex;
          align-items: center;
          margin-bottom: 7px;
          img {
            margin-right: 4px;
            height: 8px;
            width: 8px;
          }
        }
      }
    }
  }
}
