.new-business-travel {
  height: 100%;
  display: flex;
  flex-direction: column;
  .buttons-mode {
    display: flex;
    column-gap: 1rem;
  }
  .select-inputs-wrapper {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 28px;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    .multiselect-label {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      margin-right: 4px;
      margin-left: 4px;
    }
    .multiselect-card-section {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      margin-right: 4px;
      margin-left: 4px;
      margin-bottom: 16px;
    }
    .input {
      flex-grow: 1;
      flex-shrink: 0.5;
      flex-basis: calc(50% - 8px);

      margin-right: 4px;
      margin-left: 4px;
      box-sizing: border-box;
      margin-bottom: 16px;
    }

    .origin-destination-wrapper {
      width: 98%;
    }

    .buttons {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      span {
        cursor: pointer;
      }
    }
  }
  .buttons {
    margin-top: 16px;
  }
}

.add-business-travel-csv-template {
  .error-wrapper {
    margin-top: 1rem;
    width: fit-content;
    cursor: pointer;
  }
}
