.main-section-wrapper {
  .main-section {
    display: flex;
    padding: 4px 11px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .section-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      h5 {
        margin-bottom: 0px;
        margin-top: 10px;
        white-space: nowrap;
      }
      .tag-new {
        position: absolute;
        top: -4px;
        right: 12px;
        padding: 0.1rem 0.2rem;
        border-radius: 6px;
      }
    }
    img.icon {
      margin-right: 11px;
    }
    img.chevron {
      margin-left: 5px;
    }
    img.rotate-back {
      transform: rotate(0deg);
      transition: transform 150ms ease;
    }
  }
}
