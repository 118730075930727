.section-header {
  width: 80%;
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h4 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
