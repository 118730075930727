@import 'src/styles/colorVariables.scss';

.carousel-line {
  height: 0.375rem;
  margin-top: 0.8rem;
  border-radius: 5px;
}

.carousel-line-selected {
  //   background-color: $secondary-color;
}
