.sorting {
  .input-element-wrapper-2:first-child {
    input {
      max-width: 80px !important;
    }
  }
  .input-element-wrapper-2 {
    width: fit-content !important;
    input {
      max-width: 100px;
    }
  }
}
