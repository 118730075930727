.extra-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 0.5rem;
}

.extra-information p {
  margin: 0.25rem 0;
}

.extra-information p:last-child {
  margin-bottom: 0;
}
.extra-information p:first-child {
  margin-top: 0;
}
