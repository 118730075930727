.free-plan-reminder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
  p {
    margin-top: 0px;
    margin-bottom: 24px;
    text-align: center;
  }
  .buttons {
    display: flex;
    width: 100%;
    column-gap: 1rem;
  }
}
