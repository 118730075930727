.new-member {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 48px;
  }
  .select-inputs-wrapper {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 28px;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    .input {
      flex-grow: 1;
      flex-shrink: 0.5;
      flex-basis: calc(50% - 8px);

      margin-right: 4px;
      margin-left: 4px;
      box-sizing: border-box;
    }
    .buttons {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      span {
        cursor: pointer;
      }
    }
  }
  .buttons {
    margin-top: 16px;
  }
}
