.empty-data-modal-wrapper {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    padding: 1rem 1.66rem;
    border-radius: 8px;
}

.empty-data-modal-text-wrapper {
    text-align: center;
    width: 50%;
    margin: auto;
}