.register-company-step {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  &__title {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    .icon-tooltip {
      width: 18px;
      height: 18px;
    }
  }
  .input-wrapper {
    width: 400px;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  &__button-wrapper {
    width: 400px;
  }
}
