.bar-wrapper {
  height: 10px;
  max-width: 310px;
  min-width: 310px;
  display: flex;
  background-color: transparent;
  border-radius: 0px 8px 8px 0px;
  box-sizing: border-box;

  .bar {
    height: 100%;
    border-radius: 0px 8px 8px 0px;
    margin-right: -3px;
  }
}
