#nuvo-app-root-element button {
  & > .leading-6 {
    line-height: 120% !important;
  }
}

#nuvo-app-root-element button.extra-scope-1oncxb7 {
  font-size: 11px;
  margin-top: -0.7rem;
}

#nuvo-app-root-element button.extra-scope-1oncxb7 span {
  background: -webkit-linear-gradient($primary-color, $primary-light-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#nuvo-app-root-element button.extra-scope-1oncxb7:hover {
  background-color: transparent !important;
}

.extra-scope-1oncxb7:hover {
  background-color: transparent !important;
}

.extra-scope-tm5ahx {
  display: flex;
}

.extra-scope-1y1tb00 {
  width: unset !important;
}