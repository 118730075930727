@import 'src/styles';

.action-btn {
  border-radius: 8px;
  border-width: 0px;
  padding: 4px;
  margin: 0px;

  &:not([aria-disabled='true']) {
    cursor: pointer;

    &:hover {
      background-color: $purple-60-color;
    }

    &:active {
      background-color: $purple-40-color;
    }
  }
}
