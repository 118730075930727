@import 'src/styles/colorVariables';

.total-wrapper {
  display: flex;
  align-items: center;
}

.total-legend-v2 {
  background-color: $gray-dashboard;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: fit-content;
  gap: 0.5rem;

  .item {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
