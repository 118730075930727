.new-facility {
  height: 100%;
  display: flex;
  flex-direction: column;

  .facility-type {
    margin-top: 1rem;
    margin-bottom: 1rem;
    h3 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
    .select-card-section {
      margin-bottom: 0px;
    }
  }
  .categories-to-include {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-text-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .waste-water-treatment-form {
    margin-top: 1rem;
  }
}
