@import 'src/styles/colorVariables';

.slide-toggle-wrapper3 {
  display: flex;
  align-items: center;
  width: fit-content;
  // height: 14px;
  padding: 0.2rem;
  box-sizing: border-box;
  margin-right: 4px;
  border-radius: 1rem;
  background-color: $white;
  cursor: pointer;
  .status-switch {
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
  }
}
