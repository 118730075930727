.report-card-total-co2 {
  h2 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .card-info-co2 {
    border-radius: 8px;
    padding: 9px 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 47px;
    box-sizing: border-box;
    .co2 {
      margin-right: 8px;
    }
    img {
      margin-left: auto;
    }
  }
}
