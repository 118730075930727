@import 'src/styles/colorVariables.scss';
.button-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  padding: 0px;
  width: 100%;
  height: 16px;
  appearance: none;
  position: relative;
  .button-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
    border-radius: 8px;
    padding: 4px;
    &:hover {
      background-color: $purple-60-color;
    }
    &:active {
      background-color: $purple-40-color;
    }
  }
  .button-dropdown-options {
    position: absolute;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid $gray-color2;
    padding: 2px;
    border-radius: 5px;
    background-color: $input-bg-color;

    z-index: 2;
    box-sizing: border-box;
    &__item {
      padding: 8px;
      border-radius: 5px;
      cursor: pointer;
      box-sizing: border-box;
      white-space: nowrap;

      &.disabled {
        cursor: not-allowed;
      }

      &:not(.disabled) {
        &:hover {
          background: linear-gradient(
            to right,
            $select-options-active-item-bg-color,
            $select-options-active-item2-bg-color
          );
          color: $select-options-active-item-text-color;
        }
      }
    }
  }
  .bottom {
    right: 28px;
    top: 0;
  }
  .right {
    right: 28px;
    top: 0;
  }
}
