.form-header {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 1.5rem;
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
