.dashboard {
  position: relative;
  &__body {
    margin-top: 1rem;

    @media (max-width: 85rem) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, min(80vh, 30rem));
      overflow-x: auto;
    }
    grid-template-columns: 1fr 1fr;

    overflow-y: auto;
    overflow-x: hidden;
  }
  &__mode {
    width: fit-content;
    position: absolute;
    right: 3rem;
    top: 3rem;
  }
}
