@import 'src/styles/colorVariables';
@import 'src/styles/fontVariables';
.facilities {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 5rem auto 1fr;

  .infinite-scroll-wrapper-card {
    overflow-y: auto;
    margin-top: 2rem;
  }

  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  &__subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .legend-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      border: 1px solid;
    }
  }
  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  &.icon-filters {
    border-radius: 16px;
    padding: 4px 8px;
  }
  img {
    width: 12px;
    height: 12px;
  }
  &.success {
    background: #ebfafb;
    span {
      color: #38cfd9;
    }
  }
  &.loading {
    background: #f0f3fe;
    span {
      color: #6e86f7;
    }
  }
  &.error {
    background: #ffeaf4;
    span {
      color: #ff2990;
    }
  }

  &.warning {
    background: $warning-bg-color;
    span {
      color: $warning-color;
    }
  }
}

.delete-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  .buttons {
    margin-top: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
