@import 'src/styles/colorVariables';
@import 'src/styles/fontVariables';
.vehicles {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 5rem auto 1fr;

  .infinite-scroll-wrapper-card {
    overflow-y: auto;
    margin-top: 2rem;
  }

  &__header {
    width: 80%;

    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
    h4 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.new-vehicle {
  height: 100%;
  display: flex;
  flex-direction: column;
  .vehicle-type {
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
    }
    .types {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 26px;
    }
  }
  .form-wrapper-standard {
    margin-top: 0;
    .input-element {
      .checkbox-margin {
        margin-top: 10px;
      }
    }
  }

  .buttons {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.vehicle-detail {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 4.5rem 0.3fr 1fr;
  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }
  &__body {
    display: flex;

    flex-wrap: wrap;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .vehicle-detail-card {
      margin: 10px;
      padding: 13px 13px 18px 13px;
      border-width: 1px;
      border-style: dashed;
      border-radius: 5px;
      box-sizing: border-box;
      width: calc(100% - 20px);

      h1 {
        margin: 0 auto 6px auto;
        text-align: center;
      }

      p {
        margin: 0 auto 16px auto;
        text-align: center;
      }

      .buttons {
        display: flex;
        justify-content: center;
        gap: 8px;
        button {
          width: fit-content !important;
        }
      }
    }
  }

  .consumption-wrapper {
    border-radius: 5px;
    margin-top: 12px;
    padding: 33px 36px;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      .table-filters {
        display: flex;
        .input {
          margin-right: 9px;
          min-width: 120px;
          flex: 0 0 0;
        }
        .calendar-range {
          width: 210px;
          min-width: 210px;
        }
        .multi-select {
          .input__select {
            min-width: 120px;
          }
        }
      }
      .total-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}

.new-consumption {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
  }
  .select-inputs-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    & > .input {
      flex-grow: 1;
      flex-shrink: 0.5;
      flex-basis: calc(50% - 8px);
      // margin-bottom: 19px;
      margin-right: 4px;
      margin-left: 4px;
      box-sizing: border-box;
      & > .input__text-select {
        .input {
          flex-basis: 80px !important;
        }
      }
    }
    .buttons {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      span {
        cursor: pointer;
      }
    }
  }
  .buttons {
    margin-top: 16px;
  }
}
