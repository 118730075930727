.input {
  &__number {
    border-radius: 5px;
    border: 1px solid;
    align-items: center;

    &__content {
      border: none;
      padding-left: 14px;
      width: 100%;
      appearance: none;
    }
  }
}

