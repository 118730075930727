@import 'src/styles/colorVariables';

.input {
  &__text {
    border-radius: 5px;
    border: 1px solid;
    align-items: center;

    &__content {
      border: none;
      padding: 14px;
      width: 100%;
      appearance: none;
    }
    .divider-line {
      width: 1px;
      height: 50%;
      background-color: $gray-color;
      z-index: 1;
    }
  }
}