@import 'src/styles/colorVariables';

header {
  .header-organizations {
    width: 10vw;
    min-width: 11rem;
    margin-left: 1rem;
    border-radius: 5px;
    background-color: $card-bg-color;
    text-overflow: ellipsis;
    div {
      font-size: 12px;
    }
  }
}
