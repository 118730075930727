.facility-detail {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 4.5rem 0.3fr auto 1fr;

  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }

  &__body {
    display: flex;

    flex-wrap: wrap;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .facility-detail-card {
      margin: 10px;
      padding: 13px 13px 18px 13px;
      border-width: 1px;
      border-style: dashed;
      border-radius: 5px;
      box-sizing: border-box;
      width: calc(100% - 20px);

      h1 {
        margin: 0 auto 6px auto;
        text-align: center;
      }

      p {
        margin: 0 auto 16px auto;
        text-align: center;
      }

      .buttons {
        display: flex;
        justify-content: center;
        button {
          width: auto !important;
          margin-right: 8px;
        }
        button:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .invoice-wrapper {
    border-radius: 5px;
    margin-top: 12px;
    padding: 33px 36px;
    overflow-y: hidden;
    .loading-skeleton-wrapper {
      .loading-skeleton {
        height: 21px;
        margin-bottom: 21px;
      }
    }
    table {
      colgroup {
        width: calc((100% / 10));
      }
      tbody {
        tr {
          td:first-child {
            padding-right: 16px !important;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .table-filters {
        display: flex;
        .input {
          margin-right: 9px;
          min-width: 120px;
          flex: 0 0 0;
        }
        .calendar-range {
          width: 210px;
          min-width: 210px;
        }
        .multi-select {
          .input__select {
            min-width: 120px;
          }
        }
      }
      .total-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .change-document-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      cursor: pointer;
    }
    .chevron-icon-left {
      transform: rotate(90deg);
      margin-right: 14px;
    }
    .chevron-icon-right {
      transform: rotate(-90deg);
      margin-left: 14px;
    }
  }
}
