@import 'src/styles/colorVariables';
.ghg-common-section {
  margin-top: 23px;
  height: calc(100% - 55px);
  .card {
    height: 100%;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.5rem 1rem;
    .title {
      margin-bottom: 9px;
      display: flex;
      align-items: center;
      .border-left {
        width: 3px;
        border-radius: 5px;
        height: 16px;
        background-color: $primary-light-color;
      }
      h1 {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 13px;
      }
    }
    .description {
      opacity: 0.8;
      margin-bottom: 12px;
    }
  }

  .common-section-inner-div {
    display: flex;
    flex-direction: column;
  }
}
