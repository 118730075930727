@import '../../../../styles/colorVariables.scss';

.report-table {
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid $gray-color2; /* Border width and color */

  th {
    background-color: $gray-dashboard;
  }

  tr:nth-child(even) {
    background-color: $gray-dashboard;
  }
  tr:nth-child(odd) {
    background-color: $white;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
}
