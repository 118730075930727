.data-quality {
  margin-bottom: 1rem;
  border-top: 1px solid;
  padding-top: 1rem;
  &__progress-bar {
    height: 0.8rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &__progress {
      height: 100%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .sector-comparison {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }
}
