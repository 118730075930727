@import 'src/styles/colorVariables';

.input {
  &__select {
    border-radius: 5px;
    border: 1px solid;
    align-items: center;
    cursor: pointer;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      height: 16px;
      appearance: none;
      position: relative;
      flex: 1;
      min-width: 0;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        margin-left: 5px;
        width: 10.36px;
        height: 5.18px;
        margin-right: 0px;
        padding: 0 !important;
      }
    }
    .select-options {
      position: absolute;
      max-height: 170px;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid;
      border-top: none;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      padding: 5px;
      border-radius: 5px;
      background-color: $input-bg-color;
      width: calc(100% + 2px);
      left: -1px;
      top: calc(100% - 12px);
      z-index: 8;
      box-sizing: border-box;
      &--basic {
        .select-options__item {
          padding: 8px 5px 8px 5px !important;
        }
        .input-search__search__content {
          padding: 5px;
        }
      }
      &__item {
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
        box-sizing: border-box;
      }
      &__item:hover,
      .active {
        background: linear-gradient(
          to right,
          $select-options-active-item-bg-color,
          $select-options-active-item2-bg-color
        );
        color: $select-options-active-item-text-color;
      }
    }
  }

  .label-wrapper {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}

.input--basic {
  border: none !important;
  margin-top: 0 !important;
  padding-left: 0 !important;
  box-sizing: border-box;
  flex: 1 0 51px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  .input__select {
    border: none !important;
    margin-top: 0 !important;
    padding-left: 0 !important;

    &__content {
      width: 100% !important;
    }
    .select-options {
      left: -1px;
      top: 20px;
      width: calc(100% + 22px);
    }
    min-width: 100%;
  }
  .input__select__content {
    padding: 5px 0px 5px 12px;

    span {
      overflow: hidden;
      flex: 1;
    }
  }
}
