@import 'src/styles/colorVariables';
@import 'src/styles/mediaQueries';
@import 'src/styles/mixins';

header {
  position: absolute;
  right: 0;
  top: 20px;
  display: flex;
  align-items: center;
  z-index: 2;

  @include break($small) {
    position: relative;
    flex-direction: column;
    gap: 20px;
  }

  @include break($xlarge) {
    position: relative;
    top: 0rem;
    margin-bottom: 1rem;
  }

  .header-dates {
    .input__calendar {
      @include break($small) {
        position: absolute;
        left: 4rem;
        top: -2.5rem;
      }
      &__content__date {
        width: 5rem;
      }
    }
    .input {
      background-color: $card-bg-color;
      border-radius: 8px;

      input {
        background-color: $card-bg-color;
      }
      .select-options {
        background-color: $card-bg-color !important;
      }
    }
  }
  .header-options {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    .header-profile {
      position: relative;
      display: flex;
      align-items: center;
      img.profile-img {
        width: 26px !important;
        height: 26px !important;
        cursor: pointer;
      }
      .user-modal {
        position: absolute;
        padding: 17px 15px;
        border-radius: 5px;
        right: 0;
        top: 40px;
        z-index: 100;
        width: 350px;
        box-sizing: border-box;
        box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);

        &__user-info {
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;
          .user-description {
            margin-left: 9px;
            h1 {
              margin-bottom: 3px;
              margin-top: 0;
            }
            p {
              margin-top: 0;
              margin-bottom: 5px;
            }
            p:last-child {
              margin-bottom: 0px;
            }
          }
        }
        &__level {
          display: flex;
          align-items: flex-end;
          white-space: nowrap;
          margin-bottom: 14px;
          img {
            margin-right: 5px;
          }
          span {
            margin-left: 4px;
          }
        }
        ul {
          padding-top: 16px;
          list-style-type: none;
          margin-block-start: 0;
          margin-block-end: 0;
          padding-inline-start: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            margin-bottom: 16px;
            .icon-text-wrapper {
              display: flex;
              align-items: center;
              img {
                height: 10px;
                width: 10px;
                margin-right: 12px;
              }
              .only-text {
                margin-left: 22px;
              }
            }
            img.chevron {
              transform: rotate(-90deg);
              width: 8px;
              transition-duration: 0.3s;
              transition-property: transform;
            }
            img.rotate45 {
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
            }
          }
          .subsections {
            display: flex;
            flex-direction: column;
            margin-left: 34px;
            margin-bottom: 16px;
            // max-height: 50px;
            // overflow-y: auto;
            cursor: pointer;
            span {
              margin-bottom: 5px;
            }
            span:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
    .header-invite {
      position: relative;
      .invite-person-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 19px;
        height: 23px;
        cursor: pointer;
        padding: 4px 6px;
        img {
          width: 18px;
          height: 18px;
        }
      }
      .invite-modal {
        position: absolute;
        padding: 16px;
        border-radius: 5px;
        right: 0;
        top: 40px;
        z-index: 100;
        width: 235px;
        box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.03);
        .buttons-invite {
          display: flex;
          margin-top: 8px;
          // button:first-child {
          //   margin-right: 8px;
          // }
          button {
            width: 100% !important;
          }
        }
        .loading-skeleton-wrapper {
          margin-top: 8px;
          width: 100%;
          span {
            margin: 0 !important;
          }
        }
      }
    }
  }
}
