.step-carousel.big {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.step-carousel:hover {
  cursor: pointer;
}

.step-carousel {
  .label-div {
    width: fit-content;
  }
}
