.table-filters::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers like Chrome and Safari */
}

.logistics-shipment-table {
  /* last td width 5rem */
  td:last-child {
    width: 12rem;
  }
}
