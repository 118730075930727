.business-travels-header {
  display: flex;
}

.business-travels-header > .last {
  margin-left: auto;
}

.general-info-wrapper > p {
  margin: 0.1rem;
}

.business-travel-bars-wrapper {
  height: 60%;
}

.business-travel-bars-bottom-section {
  margin-top: auto;
}

.image-info {
  height: 0.75rem;
  margin-right: 0.5rem;
}

.total-info {
  border-radius: 8px;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
