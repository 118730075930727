.other-metrics-card {
  display: flex !important;
  flex-direction: column;
}
.other-metrics {
  padding: 1rem;
  border-radius: 5px;
  margin: 0 1rem 1rem 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  height: 100%;
  padding-right: 1rem;

  max-height: 100%;
  @media (max-width: 85rem) {
    max-height: 100%;
  }
}
