@import 'src/styles/colorVariables';

.input {
  &__text-select {
    border-radius: 5px;
    border: 1px solid;
    align-items: center;

    &__content {
      border: none;
      padding-left: 14px;
      width: 100%;
      appearance: none;
      flex-grow: 1.5;
      flex-shrink: 2;
      flex-basis: calc(100% - 180px);
    }

    .tag-element {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }

    .divider-line--select {
      width: 1px;
      height: 50%;
      background-color: $gray-color;
    }
    .input--basic.small {
      flex-basis: 35px !important;
      .select-options {
        top: 25px !important;
        width: calc(100% + 2px) !important;
      }
    }
    .input--basic {
      flex-basis: 70px !important;

      .input__select__content {
        // padding-right: 14px;
      }
      .select-options {
        top: 34px !important;
        width: calc(100% + 2px) !important;
      }
    }
  }
}
