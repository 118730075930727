@import 'src/styles/colorVariables';

.modern-checkbox-container {
  display: flex;
  align-items: center;
  user-select: none;
  gap: 8px;

  .checkbox-outer-circle,
  .checkbox-outer-square {
    width: 11px;
    height: 11px;
    min-width: 11px;
    min-height: 11px;
    border: 2px solid transparent;
    cursor: pointer;
    background: linear-gradient($white, $white) padding-box,
      linear-gradient(to bottom, $primary-color, $primary-light-color) border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s linear;
    opacity: 0.5;
    .checkbox-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7px !important;
      height: 7px !important;
      margin: 0 !important;
      transition: all 0.1s linear;
      img {
        height: 100% !important;
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }
  .checkbox-outer-circle.gray,
  .checkbox-outer-square.gray {
    background: linear-gradient($white, $white) padding-box,
      linear-gradient(to bottom, $gray-color, $gray-color) border-box;
  }

  .checkbox-outer-circle {
    border-radius: 50%;
  }
  .checkbox-outer-square {
    border-radius: 3px;
  }

  .unselected-circle {
    width: 0;
    height: 0;
  }

  .helper-text {
    text-align: left;
  }
}
