@import 'src/styles/colorVariables';

.select-card-section {
  margin-bottom: 0.5rem;
  .select-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .select-card {
      border: 1px solid $select-card-border-color;
      border-radius: 8px;
      box-sizing: border-box;
      background-color: $select-card-bg-color;
      padding: 19px 6px 18px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 96px;
      width: calc(33% - 7px);
      position: relative;
      cursor: pointer;
      span {
        margin-top: 10px;
        text-align: center;
        // word-break: break-all;
      }
      img {
        width: 16px;
        height: 16px;
      }
      .tooltip-card {
        position: absolute !important;
        top: -16px;
      }
    }

    .select-card--disabled {
      background-color: $select-card-disabled-bg-color;
      border-color: $select-card-disabled-border-color !important;
      cursor: auto;
    }

    .select-card--selected,
    .select-card:hover {
      border: 1px solid $select-card-selected-border-color !important;
    }

    .select-card--disabled:hover {
      border: 1px solid $select-card-disabled-border-color !important;
    }

    .select-card.small {
      width: 88px !important;
      height: 86px !important;
      padding: 0px 8px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.multiselect-card-section {
  .multiselect-card-wrapper {
    display: flex;
    flex-wrap: wrap;

    .select-card {
      border: 1px solid $select-card-border-color;
      border-radius: 5px;
      box-sizing: border-box;
      background-color: $select-card-bg-color;
      padding: 21px 6px 18px 6px;
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;
      height: 96px;
      width: calc(33% - 7px);
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      span {
        text-align: center;
      }
      img {
        width: 16px;
        height: 16px;
        margin-bottom: 10px;
      }
      .checkbox-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;
        width: 100%;
      }
    }

    .select-card--disabled {
      background-color: $select-card-disabled-bg-color;
      border-color: $select-card-disabled-border-color !important;
      cursor: auto;
    }

    .select-card--selected,
    .select-card:hover {
      border: 1px solid $select-card-selected-border-color !important;
    }

    .select-card--disabled:hover {
      border: 1px solid $select-card-disabled-border-color !important;
    }

    .select-card.small {
      width: calc(25% - 8px) !important;
      height: 22px !important;
      padding: 2px 5px !important;
      img {
        width: 11px;
        height: 11px;
      }
    }
    .select-card.medium {
      width: calc(50% - 8px) !important;
      height: 30px !important;
      padding: 0px !important;
      img {
        width: 11px;
        height: 11px;
      }
    }
  }
}

label {
  margin-bottom: 8px;
}

.multiselect-card {
  width: 100%;
  .label-wrapper {
    margin-bottom: 8px;
  }
}
