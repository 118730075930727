@import 'src/styles/colorVariables';

.calendar-range {
  .calendar-input {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .calendar-wrapper {
    z-index: 8;
    border: 1px solid $gray-color2;
    border-radius: 5px;
    background: $input-bg-color;
    // .rdrDateRangePickerWrapper {
    //   border: 1px solid $gray-color2;
    //   border-radius: 5px;
    // }
    .rdrDateInput {
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button.primary-button {
      width: 20% !important;
      margin-left: auto;
      height: 30px;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  .calendar-positioner.left {
    left: 15%;
  }

  .calendar-positioner.top {
    top: -320px;
  }
}
