.task-comment {
  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }
  &__body {
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.task-write-comment {
  display: flex;
  gap: 1rem;
  height: 3rem;
  textarea {
    padding: 0.5rem;
    height: 100%;
    border-radius: 8px;
    height: 3rem;
  }
  button {
    width: 5rem !important;
    height: fit-content;
    align-self: center;
  }
}
